import React, { useEffect } from 'react'
import FAQComponent from '../../FAQComponent/FAQComponent'
import ComponentHeader from '../../../../ComponentHeader/ComponentHeader'
import StickyHeader from '../../Header/StickyHeader'
import Footer from '../../Footer/Footer'

import './Sitemap.scss'

const FAQS = () => {

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className='security-and-trust'>
            <StickyHeader />
            <ComponentHeader title='FAQs' />
            <div className='common-container faq-section'>
                <FAQComponent />
            </div>
            <Footer />
        </div>
    )
}

export default FAQS