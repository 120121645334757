import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field } from 'formik';
import React from 'react'
import CBSelect from '../CBSelect/CBSelect';
import { formatPhoneNumber } from '../../utils';

interface InputFieldsProps {
    label: string
    name: string
    formik: any
    placeholder?: string
    width?: string;
    type?: string
    tickIcon?: boolean
    required?: boolean
    dropdown?: boolean
    textarea?: boolean
    dropdownOption: { label: string, value: string }[]
    className?:string
}
const InputFields = ({ label, name, placeholder, width = 'col-md-6', type = 'text', textarea = false, formik, tickIcon = false, required = false, dropdown = false, dropdownOption, className }: InputFieldsProps) => {
    return (
        <div className={`${width} mb-3`}>
            <label>{label} {required && <span className='text-danger'>*</span>}</label>
            {dropdown ? (
                <CBSelect
                    options={dropdownOption}
                    className={`${className} w-100`}
                    placeholder={placeholder}
                    onChange={(option: string) => {
                        formik.setFieldValue(name, option);
                    }}
                />
            ) : (
                <div className='position-relative'>
                    <Field
                        as={textarea ? 'textarea' : 'input'}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        className={`form-control ${formik.values[name] && !formik.errors[name] && 'valid-input'}`}
                        onChange={(e: any) => {
                            if (name?.startsWith('phone')) {
                                const formattedValue = formatPhoneNumber(e.target.value);
                                formik.setFieldValue(name, formattedValue);
                            } else {
                                formik.handleChange(e);
                            }
                        }}
                    />
                    {tickIcon && formik.values[name] && !formik.errors[name] && (
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            color='#00CC00'
                            className='position-absolute tick-icon'
                        />
                    )}
                </div>
            )}
            <ErrorMessage name={name} component='div' className='error-message text-danger text-start' />
        </div>
    );
};

export default InputFields;
