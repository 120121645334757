import storage from 'redux-persist/lib/storage';

export const patientProfilePersistConfig = {
  key: 'patientProfile', // Key for the persistor
  storage,
  blacklist: [] // Optionally, you can specify fields to exclude from persistence
};

export const adminProfilePersistConfig = {
  key: 'adminProfile', // Key for the persistor
  storage,
  blacklist: [] // Optionally, you can specify fields to exclude from persistence
};

export const providerProfilePersistConfig = {
  key: 'providerProfile', // Key for the persistor
  storage,
  blacklist: [] // Optionally, you can specify fields to exclude from persistence
};

export const userMessagePersistConfig = {
  key: 'userMessage', // Key for the persistor
  storage,
  blacklist: [] // Optionally, you can specify fields to exclude from persistence
};

export const AppointmentSchedulePersistConfig = {
  key: 'userMessage', // Key for the persistor
  storage,
  blacklist: [] // Optionally, you can specify fields to exclude from persistence
};
export const patientFormProfilePersistConfig = {
  key: 'patientFormsData', // Key for the persistor
  storage,
  blacklist: [] // Optionally, you can specify fields to exclude from persistence
};
export const onBoardingPersistConfig = {
  key: 'onBoarding', // Key for the persistor
  storage,
  blacklist: [] // Optionally, you can specify fields to exclude from persistence
};
export const GetSchedulingRulePersistConfig = {
  key: 'schedulingRule', // Key for the persistor
  storage,
  blacklist: [] // Optionally, you can specify fields to exclude from persistence
};

export const commonPersistConfig = {
  key: 'root', // Key for the persistor
  storage,
  blacklist: [
    'themeColor',
    'language',
    'detailsPageTitle',
    'isUserSignIn',
    'slider',
    'fullScreen',
    'startVideoCall',
    'isOpenOnboarding'
  ] // Optionally, you can specify fields to exclude from persistence
};


