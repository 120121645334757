import React, { useEffect } from 'react'
import StickyHeader from '../../Header/StickyHeader'
import Footer from '../../Footer/Footer'
import CommonSiteMap from './CommonSiteMap'
import images from '../../../../../Assets/Images'

const Aicpasoc = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div>
            <StickyHeader />
            <div className='common-container'>
                <CommonSiteMap title='AICPASOC' description='System and Organization Controls (SOC) Reports are independent third-party examination reports that demonstrate how powered by CentiBlick achieves key compliance controls and objectives. The purpose of these reports is to help you and your auditors understand the controls established to support operations and compliance.' imgUrl={images.AICPASOC} />
            </div>
            <Footer />
        </div>
    )
}

export default Aicpasoc