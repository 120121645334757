import React, { useEffect, useState } from 'react';
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import './CheckOutPage.scss'
import images from '../../../../../Assets/Images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ToastMessage } from '../../../../../Components/Common/ToastMessage';
import moment from 'moment';

declare global {
    interface Window {
        onRecaptchaSuccess: () => void;
        grecaptcha: any;
    }
}

const CheckOutPage = ({ handleBackButtonClick,
    handleCheckoutButtonClick,
    planName }: any) => {
    const stripe: any = useStripe();
    const elements = useElements();
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [cardError, setCardError] = useState<string | null>(null);
    const [recaptchaError, setRecaptchaError] = useState<string | null>(null);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);

    const price = localStorage.getItem('price');

    useEffect(() => {
        const renderRecaptcha = () => {
            if (window.grecaptcha) {
                window.grecaptcha.render("recaptcha-container", {
                    sitekey: process.env.REACT_APP_reCAPTCHA_SITE_KEY,
                    callback: "onRecaptchaSuccess",
                });
            }
        };

        window.onRecaptchaSuccess = () => {
            setRecaptchaVerified(true); // Update state when reCAPTCHA is successfully completed
            setRecaptchaError(null); // Clear reCAPTCHA error when successful
        };

        if (window.grecaptcha) {
            renderRecaptcha();
        } else {
            window.onload = renderRecaptcha;
        }
    }, []);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        // Ensure reCAPTCHA is verified before proceeding
        if (!recaptchaVerified) {
            setRecaptchaError('Please complete the reCAPTCHA.');
        }

        setPaymentProcessing(true);

        if (!stripe || !elements) {
            setCardError('Stripe has not loaded properly.');
            setPaymentProcessing(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error: cardValidationError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (cardValidationError) {
            setCardError(cardValidationError.message); // Set card error message
            setPaymentProcessing(false);
        } else {
            console.log('Payment Method Created:', paymentMethod);
            // Add your API call here to process the payment on the server side.
            handleCheckoutButtonClick();
            ToastMessage('success', 'Payment Successful!');
            setPaymentProcessing(false);
        }
    };

    return (
        <div className="w-100 h-100">
            <div className="d-flex flex-column onboarding-main-flow gap-5 h-100 align-items-center m-0">
                <form onSubmit={handleSubmit} className="payment-form stepping-form">
                    <div className='w-100 stepping-details' style={{ marginBottom: '30px' }}>
                        <h2 className='step-sub-heading text-center'>Enter Credit Card Information</h2>
                    </div>
                    <div className='checkout-section'>
                        <div className='logo-section'>
                            <img src={images.LandingPageFooterLogo} alt="" className='onboard-logo' />
                        </div>
                        <div className='step-section d-flex align-items-center gap-3 justify-content-center'>
                            <div className='d-flex align-items-center gap-2'>
                                <FontAwesomeIcon icon={faCircleCheck} color='#5F6A7D' size='2x' />
                                <h1 className='text-secondary'>Your Info</h1>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faMinus} />
                                <FontAwesomeIcon icon={faMinus} />
                            </div>
                            <div className='d-flex align-items-center gap-2'>
                                <div className='round-number d-flex align-items-center justify-content-center'>2</div>
                                <h1>Payment</h1>
                            </div>
                        </div>
                        <div className="payment-amount">
                            <p className='payment-text'>
                                Payment Amount
                            </p>
                            <p className='pay-amount'>
                                ${price}
                            </p>
                            <span>+tax</span>
                            <p className='payment-text'>This is a monthly payment. We'll automatically charge you starting today, {moment().format('MM/DD/YYYY')}.</p>
                        </div>

                        <div className="card-input">
                            <p className='payment-text pb-2'>Card number</p>
                            <CardElement options={{ hidePostalCode: true }} />
                            {cardError && <div className="error-message">{cardError}</div>}
                        </div>

                        <div className="captcha d-flex flex-column align-items-center">
                            <div id="recaptcha-container"></div>
                            {recaptchaError && <div className="error-message">{recaptchaError}</div>}
                        </div>

                        <div className="button-group d-flex">
                            <button type="button" onClick={handleBackButtonClick} className="back-button">
                                Go Back
                            </button>
                            <button type="submit" disabled={!stripe || paymentProcessing} className="pay-button">
                                {paymentProcessing ? 'Processing...' : 'Pay'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CheckOutPage;
