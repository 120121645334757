import React, { useEffect, useState } from "react";

// assets
import images from "../../../../Assets/Images";
import { useDispatch } from "react-redux";
import { storeCheckoutSessionData } from "../../../../Store/Thunk/Stripe/storeCheckoutSessionThunk";
import { getLocalStorage } from "../../../../Components/Patient/Utils/AuthHandlers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface step5Type {
  handleBackButtonClick: any;
  handleCheckoutButtonClick: any;
  planName?: string;
}

const Step5 = ({
  handleBackButtonClick,
  handleCheckoutButtonClick,
  planName,
}: step5Type) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState<"monthly" | "yearly">(
    "monthly"
  );

  const userData = getLocalStorage('userData')

  const authToken = getLocalStorage("userToken");
  const provider = getLocalStorage("provider");

  const [counter, setCounter] = useState(1);

  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState<any>("");

  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
  };

  const handleChange = async (event: any) => {
    const newValue = parseInt(event.target.value, 10) || 0;
    setCounter(newValue);
  };

  const handleSubmit = async (values: any) => {
    toast.loading("Please wait...")
    console.log("🚀 ~ handleSubmit ~ values:", values);

    const currunt_url = new URL(window.location.href);
    const domain_name = currunt_url.hostname;

    let payload;
    if (domain_name === "localhost") {
      payload = {
        lookup_key: "50_dollar_price_clinic",
        success_url: `${planName === 'individual' ? "http://localhost:3000/provider/login" : "http://localhost:3000/success"}`,
        cancel_url: `http://localhost:3000/canceled`,
        account_id: userData.sub
      };
    } else {
      payload = {
        lookup_key: "50_dollar_price_clinic",
        success_url: `${planName === 'individual' ? `https://provider.centiblickconnect.com/provider/login` : `https://${domain_name}/success`}`,
        cancel_url: `https://${domain_name}/canceled`,
        account_id: userData.sub
      };
    }
    const response: any = await dispatch(
      storeCheckoutSessionData({ payload: payload, authToken, })
    );

    if (response.payload.status === 200) {
      toast.dismiss()
      const url = response?.payload.data.url
      if (planName === 'clinic') {
        if (selectedOption === 'monthly') {
          localStorage.setItem('price', JSON.stringify(50 * provider?.length))
        } else {
          localStorage.setItem('price', JSON.stringify(500 * provider?.length))
        }
      } else {
        if (selectedOption === 'monthly') {
          localStorage.setItem('price', JSON.stringify(35));
        } else {
          localStorage.setItem('price', JSON.stringify(350))
        }
      }
      handleCheckoutButtonClick();
      // window.location.href = url;
    } else {
      toast.dismiss()
    }
  };

  const SuccessDisplay = ({ sessionId }: any) => {
    return (
      <section>
        <div className="product Box-root">
          <div className="description Box-root">
            <h3>Subscription to starter plan successful!</h3>
          </div>
        </div>
        <form action="/create-portal-session" method="POST">
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form>
      </section>
    );
  };

  const Message = ({ message }: any) => (
    <section>
      <p>{message}</p>
    </section>
  );

  if (!success && message === "") {
    return (
      <div className="w-100 h-100">
        <div className="d-flex flex-column onboarding-main-flow gap-5 h-100 align-items-center m-0">
          <div className='w-100 d-flex flex-column gap-4 stepping-details'>
            {/* <h1 className='step-heading'>Step 3</h1> */}
            <h2 className='step-sub-heading text-center m-0'>Step 3: Complete payment information</h2>
            {/* <p className='step-desc'>You’re almost done! Enter your payment details to finalize your subscription. Once completed, we’ll send registration email to all the users you’ve added, and our onboarding specialist will reachout to customize your</p> */}
          </div>
          <div className="d-flex flex-column onboarding-stepping payment-section-first payment-section z-1 h-auto w-100 stepping-form p-4" style={{ width: "65%" }}>
            <h1 className="onboarding-heading" style={{ fontSize: "16px" }}>
              Your Order
            </h1>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-column">
                <p className="blue-Heading">
                  {planName === "clinic" ? "CLINIC" : "INDIVIDUAL"}
                </p>
                <div className="w-100 mt-3 d-flex justify-content-between gap-4">
                  <div
                    className={`col border-div ${selectedOption === "monthly" ? "selected-box" : ""
                      }`}
                    style={{ padding: "8px 12px" }}
                    onMouseEnter={() => handleOptionClick("monthly")}
                    onClick={() => handleOptionClick("monthly")}
                  >
                    <div className="inner-content-essential p-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Monthly</h4>
                        <img
                          src={
                            selectedOption === "monthly"
                              ? images.Icon
                              : images.FilledCheckMark
                          }
                          alt="circle Icon"
                          height={20}
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <h3 className="mt-2">
                          {planName === "clinic" ? "$50" : "$35"}
                        </h3>
                        <h5 className="">/month</h5>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col border-div ${selectedOption === "yearly" ? "selected-box" : ""
                      }`}
                    style={{ padding: "8px 12px" }}
                    onClick={() => handleOptionClick("yearly")}
                    onMouseEnter={() => handleOptionClick("yearly")}
                  >
                    <div className="inner-content-essential p-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-flex gap-2 align-items-center">
                          Yearly{" "}
                          <span className="price-off">Get two months free</span>
                        </h4>
                        <img
                          src={
                            selectedOption === "yearly"
                              ? images.Icon
                              : images.FilledCheckMark
                          }
                          alt="circle Icon"
                          height={20}
                        />
                      </div>
                      <div className="d-flex align-items-end">
                        <h3 className="mt-2">
                          {planName === "clinic" ? "$500" : "$350"}
                        </h3>
                        <h5 className="">/year</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "12px 0px 0px" }} />
              </div>
              <div className="subscribe-content">
                <h2 className="heading" style={{ textDecoration: "underline" }}>
                  30-day Money Back Guarantee
                </h2>
                <p className="onboarding-detail">
                  CentiBlick Telehealth offers a 30-day money-back guarantee on
                  subscriptions, ensuring you can try the platform risk-free and
                  get a full refund if you're not completely satisfied.
                </p>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                  <div className="subscribe-content">
                    <h2 className="heading">
                      {planName === "clinic"
                        ? `${provider?.length} ${provider?.length === 1 ? "License" : "Licenses"}`
                        : "Individual"}
                    </h2>
                    {/* <p className='onboarding-detail'> $ {counter * (selectedOption === "monthly" ? 69 : 800)} per license/per month</p> */}
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between pt-2 subtotal-div">
                    <p className="">SUBTOTAL</p>
                    <p>
                      {planName === 'individual' ? 1 : provider?.length}  x{" "}
                      {selectedOption === "monthly"
                        ? planName === "clinic"
                          ? "$50"
                          : "$35"
                        : planName === "clinic"
                          ? "$500"
                          : "$350"}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between pt-2 montly-subTotal">
                    <h2 className="heading">
                      {selectedOption === "monthly" ? "Monthly" : "Yearly"}{" "}
                      Subtotal
                    </h2>
                    <p>
                      ${" "}
                      {(planName === 'individual' ? 1 : provider?.length) *
                        (selectedOption === "monthly"
                          ? planName === "clinic"
                            ? 50
                            : 35
                          : planName === "clinic"
                            ? 500
                            : 350)}
                      .00
                    </p>
                  </div>
                </div>
                <p className="onboarding-detail pt-4">*T&C Applied</p>
                <div className="d-flex justify-content-between col-lg-12" style={{ paddingTop: '36px' }}>
                  <button
                    className="onboarding-back main-delete-theme-btn"
                    onClick={handleBackButtonClick}
                  >
                    Back
                  </button>
                  <input
                    type="hidden"
                    name="lookup_key"
                    value={`50_dollar_price_clinic`}
                  />
                  <button
                    className="onboarding-checkout"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (success && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
};

export default Step5;
