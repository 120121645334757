import React, { useEffect } from 'react'
import images from '../../../../Assets/Images'

//formik
import * as Yup from 'yup';
import { Form, Formik } from 'formik';


//css
import './CommonContactForm.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import InputFields from '../../InputFields/InputFields';

const addOnFeatures = [
    { title: 'Streamline Operations:', desc: 'Simplify workflows by integrating tools like 2-way EHR and centralized patient scheduling.' },
    { title: 'Reduce Security Risks:', desc: 'Consolidate systems and replace vulnerable, disconnected solutions to safeguard patient data.' },
    { title: 'Enhance Compliance:', desc: 'Ensure your practice stays HIPAA-compliant with features like secure payments, AI charting, and remote monitoring.' },
    { title: 'Lower Costs:', desc: 'Reduce inefficiencies and eliminate the expense of maintaining multiple outdated systems.' },
]

const contactFeatures = [
    { title: 'Scalable Features to', desc: 'grow with your practice' },
    { title: 'Dedicated Support to', desc: 'ensure success every step of the way' },
    { title: 'Customizable Solutions to', desc: "match your practice's needs" },
    { title: 'HIPAA-Compliant Telehealth for', desc: 'secure, seamless patient care' },
]

interface CommonContactFormProps {
    type: 'contact' | 'addOn'
    fields: any
}

const CommonContactForm = ({ fields, type }: CommonContactFormProps) => {

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        ehr: '',
        provider: '',
        comments_needs: '',
        specialty: '',
        title: ''
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required('first name is required'),
        lastName: Yup.string().required('last name is required'),
        email: Yup.string().email('email is not valid').required('email is required'),
        phoneNumber: Yup.string().required('phone number is required'),
        companyName: type === 'contact' ? Yup.string().nullable() : Yup.string().required('company name is required'),
        title: type === 'contact' ? Yup.string().required('title/role is required') : Yup.string().nullable(),
        ehr: Yup.string(),
        provider: Yup.string(),
        comments_needs: Yup.string(),
        specialty: Yup.string(),
    })

    const handleSubmit = (values: any) => {
        console.log("🚀 ~ handleSubmit ~ values:", values)
    }
    return (
        <div className='common-contact-form z-2' style={{backgroundColor:'#fff'}}>
            <img src={images.PricingBg} alt="" className='first-bg' />
            <img src={images.PricingBg} alt="" className='second-bg' />
            <img src={images.PricingBg} alt="" className='third-bg' />
            <img src={images.PricingBg} alt="" className='forth-bg' />
            <div className='add-on-features position-relative text-center common-container'>
                {
                    type === 'addOn' ?
                        <div className='w-100'>
                            <div className='container add-on-needs z-2'>
                                <h1>
                                    Improve Workflow, <br /> Mitigate Risks, & Enhance <br /> Your Practice Today
                                </h1>
                                <div className='row platform-feature-details'>
                                    <p className='feature-title mt-2'>
                                        Why Choose CentiBlick Add-Ons?
                                    </p>
                                    {
                                        addOnFeatures?.map((data) => (
                                            <div className='platforn-details'>
                                                <div className='d-flex gap-2'>
                                                    <FontAwesomeIcon icon={faCircleCheck} color='#516cd3' className='tick-icon mt-1' />
                                                    <p><strong>{data.title}</strong> {data.desc}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <p className='feature-title mt-2'>
                                        What’s Next?
                                    </p>
                                    <p>
                                        Request a personalized quote today and see how CentiBlick can enhance your practice while protecting your patients and your reputation.
                                    </p>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='w-100'>
                            <div className='container add-on-needs z-2'>
                                <h1>
                                    Let’s Discuss Your Needs!
                                </h1>
                                <div className='row platform-feature-details'>
                                    <p className='feature-title mt-2'>
                                        What You’ll Get When You Contact Us:
                                    </p>
                                    <ol style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
                                        <li>
                                            A tailored walkthrough of our platform and its features.
                                        </li>
                                        <li>
                                            Answers to all your questions about implementation, pricing, and support.
                                        </li>
                                        <li>
                                            Expert advice on how CentiBlick can align with your practice’s goals.
                                        </li>
                                    </ol>
                                    <p className='feature-title mt-2'>
                                        Our Key Offerings Include:
                                    </p>
                                    {
                                        contactFeatures?.map((data) => (
                                            <div className='platforn-details'>
                                                <div className='d-flex gap-2'>
                                                    <FontAwesomeIcon icon={faCircleCheck} color='#516cd3' className='tick-icon mt-1' />
                                                    <p><strong>{data.title}</strong> {data.desc}</p>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                }
                <div className='w-75 form-div position-relative'>
                    <div className='position-absolute static-bg top-0'></div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            (formik) => (
                                <Form className='addOn-form position-relative text-start'>
                                    <div className='row'>
                                        {
                                            fields?.map((field: any, index: any) => (
                                                <InputFields
                                                    key={index}
                                                    label={field.label}
                                                    name={field.name}
                                                    placeholder={field?.placeholder}
                                                    width={field?.width}
                                                    type={field?.type}
                                                    tickIcon={field?.tickIcon}
                                                    required={field?.required}
                                                    dropdown={field?.dropdown}
                                                    textarea={field?.textarea}
                                                    dropdownOption={field?.dropdownOption}
                                                    formik={formik}
                                                    className='addOn-dropdown'
                                                />
                                            ))
                                        }
                                    </div>                                    
                                    <button className='save-theme-btn w-100' type='submit'>Submit</button>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default CommonContactForm