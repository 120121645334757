import React, { useEffect, useState } from 'react'

//css
import './CentiBlickHomePage.scss'
import StickyHeader from './Header/StickyHeader'
import images from '../../../Assets/Images'
import Footer from './Footer/Footer'
import { useNavigate } from 'react-router-dom'
import ResourceComponent from './ResourceComponent/ResourceComponent'
import { LandingLogo } from '../../../Assets/Svgs'

const CentiBlickHomePage = () => {

    const navigate = useNavigate()

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [navigate]);
    const Features = [
        { name: 'Compliance First', details: 'CentiBlick is designed to fully comply with HIPAA and HHS guidelines' },
        { name: 'Enhanced Security', details: 'Secure your telehealth sessions with end-to-end encryption, patient logins, & provider authentication' },
        { name: 'Seamless Integration', details: 'Easily integrate CentiBlick into your existing workflows with minimal disruption' },
        { name: 'Risk Mitigation', details: 'Stay ahead of regulatory changes & avoid penalties with a platform built for today’s healthcare environment' }
    ]

    const complianceData: any = [
        {
            title: "Secure Encrypted Appointment Links",
            title2: 'CentiBlick Appointment links have usernames and passwords',
            description:
                "Other platforms’ open links sent via text or emails without login requirements pose significant security risk. These links can be intercepted or accessed by unauthorized individuals, leading to potential breaches of protected health information (PHI).",
            imageUrl: images.FirstCompliance,
            buttonText: "See HIPAA Guidelines",
            details: {
                firstContent: "Telehealth Platform's compliance issues are generated most often because legacy telehealth platforms are repurposed video conferencing software. Despite claiming HIPAA compliance, they lack essential security features such as secure invite links, patient logins, and provider authentication and identity protection. ",
                firstBoldContent: "Healthcare providers who use these platforms are exposed to legal and financial risks",
                secondContent: "because of deficiencies on HIPAA requirements such as",
                secondBoldContent: "Security",
                underlineContent: "(45 CFR § 164.306(a)):",
                thirdContent: "confidentiality, integrity, and availability of electronic protected health information (ePHI)"
            },
            details1: {
                underlineContent: "According to HHS, healthcare providers are responsible",
                content: "for ensuring that their chosen telehealth platform meets all necessary security and compliance standards. If a breach occurs due to inadequate platform security, the liability lies with the provider who picked a platform without required safeguards"
            }
        },
        {
            title: "Access Control & Authentication",
            title2: 'CentiBlick platform encounters are verified and recorded',
            description:
                "HIPAA mandates the verification of individuals accessing PHI. Other platforms' open links without authentication do not meet this requirement, increasing the risk of unauthorized access.",
            imageUrl: images.SecondCompliance,
            buttonText: "See HIPAA Guidelines",
            details: {
                firstContent: "Telehealth Platform's compliance issues are generated most often because legacy telehealth platforms are repurposed video conferencing software. Despite claiming HIPAA compliance, they lack essential security features such as secure invite links, patient logins, and provider authentication and identity protection.",
                firstBoldContent: " Healthcare providers who use these platforms are exposed to legal and financial risks",
                secondContent: "because of deficiencies on HIPAA requirements such as",
                secondBoldContent: "Authentication",
                underlineContent: "(45 CFR § 164.312(d))",
                thirdContent: "of individuals accessing PHI with access controls, including logins and usernames."
            },
            details1: {
                underlineContent: "According to HHS, healthcare providers are responsible",
                content: "for ensuring that their chosen telehealth platform meets all necessary security and compliance standards. If a breach occurs due to inadequate platform security, the liability lies with the provider who picked a platform without required safeguards"
            }
        },
        {
            title: "Provider Registration & Identity Management",
            title2: 'CentiBlick Verifies and Secures All  Platform Providers',
            description:
                "As part of HIPAA compliance, it is essential to verify the identity and credentials of all providers using the telehealth platform. This ensures that only authorized and qualified individuals have access to sensitive patient information.",
            imageUrl: images.ThirdCompliance,
            buttonText: "See HIPAA Guidelines",
            details: {
                firstContent: "Telehealth Platform's compliance issues are generated most often because legacy telehealth platforms are repurposed video conferencing software. Despite claiming HIPAA compliance, they lack essential security features such as secure invite links, patient logins, and provider authentication and identity protection.",
                firstBoldContent: "Healthcare providers who use these platforms are exposed to legal and financial risks",
                secondContent: "because of deficiencies on HIPAA requirements such as",
                secondBoldContent: "Verification",
                underlineContent: "(45 CFR § 164.514(h))",
                thirdContent: "of identity and credentials of all providers using the telehealth platform."
            },
            details1: {
                underlineContent: "According to HHS, healthcare providers are responsible",
                content: "for ensuring that their chosen telehealth platform meets all necessary security and compliance standards. If a breach occurs due to inadequate platform security, the liability lies with the provider who picked a platform without required safeguards"
            }

        },
        {
            title: "End-to-End Encryption",
            title2: 'CentiBlick Ensures both the Transmission and Sessions are fully encrypted',
            description:
                "While other platforms’ telehealth sessions might be encrypted, the transmission of open links is not, exposing sensitive information to potential breaches and unauthorized patient and provider data access.",
            imageUrl: images.ForthCompliance,
            buttonText: "See HIPAA Guidelines",
            details: {
                firstContent: "Telehealth Platform's compliance issues are generated most often because legacy telehealth platforms are repurposed video conferencing software. Despite claiming HIPAA compliance, they lack essential security features such as secure invite links, patient logins, and provider authentication and identity protection.",
                firstBoldContent: "Healthcare providers who use these platforms are exposed to legal and financial risks",
                secondContent: "because of deficiencies on HIPAA requirements such as",
                secondBoldContent: "Encryption",
                underlineContent: "(45 CFR § 164.312(e)(1))",
                thirdContent: "of ePHI transmitted over electronic networks to protect against unauthorized access."
            },
            details1: {
                underlineContent: "According to HHS, healthcare providers are responsible",
                content: "for ensuring that their chosen telehealth platform meets all necessary security and compliance standards. If a breach occurs due to inadequate platform security, the liability lies with the provider who picked a platform without required safeguards"
            }
        },
    ];

    const pricingData = [
        {
            title: "Individual Provider",
            description:
                "Our individual provider platform offers a secure and encrypted solution for your telehealth needs. Enjoy identity protection, verified patient profiles, detailed encounter summaries, and a modern best-in-class experience for both you and your patients.",
            imageUrl: images.EmployerWebsitePicture,
            buttonText: "Get Started for $35/month",
            use: '',
            price: "",
            duration: '',
            url: '/pricing'
        },
        {
            title: "Clinic Platform",
            description:
                "Our Clinic Platform includes all individual provider features plus a state-of-the-art admin center. Enjoy provider room directories, detailed patient and provider profiles, encounter summaries, and comprehensive platform analytics for a streamlined, modern experience.",
            imageUrl: images.ClinicPictureOption,
            buttonText: "View Pricing & Features",
            use: '',
            price: "",
            duration: '',
            url: '/pricing'
        },
        {
            title: "Add-On Features",
            description:
                "Start with the core features and expand with add-on functionalities as your needs grow. Access HIPAA Compliant Payments, 2-way EHR Integration, Flexcare virtual + location scheduling, patient appointments, 360 Medical Records, AI charting and so much more.",
            imageUrl: images.PlatformUpgradesImage,
            buttonText: "Explore Add on Features",
            price: "",
            url: '/add-on-features'
        },
    ];

    const securityData = [
        { title: 'HIPPA Compliant', navigaterUrl: '/hippa' },
        { title: 'GDPR Compliant', navigaterUrl: '/gdpr' },
        { title: 'HiTrust Compliant', navigaterUrl: '/hi-trust' },
        { title: 'SOC2 Certified', navigaterUrl: '/aicpasoc' },
        { title: 'FedRAMP Certified', navigaterUrl: '/fedramp' },
        { title: 'End-to-end Encryption', navigaterUrl: '/aicpasoc' },
    ]

    const [selectedCompliance, setSelectedCompliance] = useState(complianceData[0]);
    const [animationClass, setAnimationClass] = useState('');

    const [learnMore, setLearnMore] = useState<boolean>(false)

    const handleComplianceChange = (data: any, index: any) => {
        setLearnMore(false)
        const currentIndex = complianceData.findIndex((item: any) => item.title === selectedCompliance.title);

        if (selectedCompliance.title !== data.title) {
            if (index > currentIndex) {
                setAnimationClass('slide-in-right');
            } else if (index < currentIndex) {
                setAnimationClass('slide-in-left');
            }

            setSelectedCompliance(data);
            setTimeout(() => {
                setAnimationClass('');
            }, 500);
        }
    };

    const handleLearnMore = () => {
        setLearnMore(true)
    }
    const handleBack = () => {
        setLearnMore(false)
    }

    return (
        <div className='centiblick-home-page'>
            <StickyHeader />
            <div style={{ backgroundColor: "#fff" }}>
                <div className='hipaa-compliant position-relative d-flex gap-4 position-relative common-container'>
                    <img src={images.PricingBg} alt="" className='first-bg' />
                    <img src={images.PricingBg} alt="" className='second-bg' />
                    <img src={images.PricingBg} alt="" className='third-bg' />
                    <img src={images.PricingBg} alt="" className='forth-bg' />
                    <img src={images.PricingBg} alt="" className='fifth-bg' />
                    <img src={images.PricingBg} alt="" className='sixth-bg' />
                    <div className='z-1'>
                        <h1 className='my-4'>
                            HIPAA & HHS Compliant
                        </h1>
                        <h2>
                            Telehealth Platform
                        </h2>
                        <p className='my-4'>
                            CentiBlick provides a secure, compliant telehealth platform that integrates seamlessly into your practice, reducing legal risks while enhancing patient care
                        </p>
                        <div className='mt-3 d-flex gap-5'>
                            <button className='shadow-none save-theme-btn' onClick={() => navigate('/pricing')}>Get Started</button>
                            {/* <button className='shadow-none save-theme-btn' onClick={() => navigate('/request-demo')}>Request a Demo</button> */}
                        </div>
                    </div>
                    <div className='z-1 w-100'>
                        <div className="macbook-image">
                            <img src={images.LandingPageImage} alt="page" className='w-100' />
                            <video src={images.ProviderVideo} autoPlay={true} loop muted className="providerVideo" />
                            <img src={images.Notch} alt="notch" className="notch" />
                            <video src={images.PatientVideo} autoPlay={true} loop muted className="patientVideo" />
                            <img src={LandingLogo} alt="sideImage" className="sideImage" />
                        </div>
                        <p className='mt-3 text-center' style={{ color: "#516cd3" }}>Telehealth powered by CentiBlick</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#fff" }}>
                <div className='centiblick-features common-container'>
                    <div className='row'>
                        {
                            Features?.map((data: any) => (
                                <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12 d-flex mt-4'>
                                    <div className='feature-details'>
                                        <h1 className='mb-3'>{data.name}</h1>
                                        <p>{data.details}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#f5f5f5", boxShadow: "17px 20px 44px 1px rgba(160, 160, 160, 0.2509803922)" }}>
                <div className='compliance-container w-100 common-container'>
                    <h1 className='compliance-header'>Must Have Compliance Features</h1>
                    <div className='w-100 d-flex justify-content-end align-items-center position-relative'>
                        <div className={`position-absolute start-0 z-1 ${deviceWidth < 1025 && 'd-none'}`} style={{ minWidth: "39%" }}>
                            {complianceData.map((data: any, index: any) => (
                                <div
                                    className='compliance-data'
                                    key={index}
                                    onClick={() => handleComplianceChange(data, index)}
                                    style={selectedCompliance.title === data.title ? { background: "#516cd3", color: "#fff", fontWeight: "700", padding: "36px" } : {}}
                                >
                                    {data.title}
                                </div>
                            ))}
                        </div>
                        <div className={`compliance-div d-flex gap-3 ${animationClass}`}>
                            <img src={selectedCompliance.imageUrl} alt="" className={`${learnMore && 'd-none'}`} />
                            <div className='compliance-details' style={{ padding: learnMore ? "36px 72px" : "" }}>
                                {learnMore ?
                                    <div className='compliance-height'>
                                        <div>
                                            <p>{selectedCompliance?.details?.firstContent}{" "}
                                                <strong>{selectedCompliance?.details?.firstBoldContent}</strong>{" "}
                                                {selectedCompliance?.details?.secondContent}{" "}
                                                <strong>{selectedCompliance?.details?.secondBoldContent}</strong> {" "}
                                                <span>{selectedCompliance?.details?.underlineContent}</span>{" "}
                                                {selectedCompliance?.details?.thirdContent}
                                            </p>
                                            <p className='mt-3'>
                                                <span>
                                                    {selectedCompliance?.details1?.underlineContent}{" "}
                                                </span>
                                                {selectedCompliance?.details1?.content}
                                            </p>

                                        </div>
                                    </div>
                                    :
                                    <div className='compliance-height'>
                                        <h1>{selectedCompliance.title2}</h1>
                                        <p className='mt-3'>{selectedCompliance.description}</p>
                                    </div>
                                }
                                <div className='d-flex w-100 gap-4'>
                                    <button className=' shadow-none save-theme-btn w-100 px-0' onClick={learnMore ? handleBack : handleLearnMore}>{learnMore ? "Back" : selectedCompliance.buttonText}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        deviceWidth < 1025 &&
                        <div className='d-flex gap-3'>
                            {complianceData.map((data: any, index: any) => (
                                <div className='control-tooltip'>
                                    <div
                                        className='responsive-compliance-data cursor-pointer'
                                        key={index}
                                        onClick={() => handleComplianceChange(data, index)}
                                        style={selectedCompliance.title === data.title ? { background: "#516cd3", width: "35px", borderRadius: "50px" } : {}}
                                    >
                                    </div>
                                    <span className="control-tooltip-text">{data.title}</span>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
            <div style={{ background: 'linear-gradient(180deg, rgba(226, 232, 254, 0.00) -4.04%, #E2E8FE 64.63%)' }}>
                <div className='pricing-container common-container'>
                    <h1 className='text-center'>We Make Compliance Easy: <br /> Just Swap The Link!</h1>
                    <div className='pricing-div mt-5'>
                        {pricingData.map((item, index) => (
                            <div className="pricing-item w-100" key={index}>
                                <img src={item.imageUrl} alt={item.title} className="pricing-image" />
                                <div className='d-flex flex-column justify-content-evenly align-items-center'>
                                    <div>
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center w-100 pricing-button'>
                                        <h2 className='price'>{item.price} <span>{item.duration}</span></h2>
                                        <button className=' shadow-none save-theme-btn' onClick={() => navigate(item.url)}>{item.buttonText}</button>
                                    </div>
                                    <p className='useage'>{item.use}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='security-main-div position-relative z-2' style={{ backgroundColor: "#fff", boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.1019607843)" }}>
                <div className='security-container common-container'>
                    <div className='security-div w-50'>
                        <h1 className=''>Enterprise Security</h1>
                        <div className='security-type mt-5'>
                            {
                                securityData.map((item, index) => (
                                    <div className='d-flex align-items-center security-details'
                                        onClick={() => navigate(item.navigaterUrl)}>
                                        <img src={images.SecurityCheck} alt="" />
                                        <h1>{item.title}</h1>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ResourceComponent />
            </div>
            <Footer />
        </div>
    )
}

export default CentiBlickHomePage