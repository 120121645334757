import React, { useEffect } from 'react'
import StickyHeader from '../../Header/StickyHeader'
import Footer from '../../Footer/Footer'
import CommonSiteMap from './CommonSiteMap'
import images from '../../../../../Assets/Images'

const FedRAMP = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div>
            <StickyHeader />
            <div className='common-container'>
                <CommonSiteMap title='FedRAMP' description='The Federal Risk and Authorization Management Program (FedRAMP) is a government-wide program that promotes the adoption of secure cloud services across the federal government by providing a standardized approach to security assessment, authorization, and continuous monitoring for cloud products and services.' imgUrl={images.Fedramp} />
            </div>
            <Footer />
        </div>
    )
}

export default FedRAMP