import React from "react";
// hook
import { usePricing } from "./usePricing";
// components
import Monthly from "./Components/Monthly";
import Yearly from "./Components/Yearly";
import SignInHeader from "../../Components/Common/SignInHeader/SignInHeader";

// asstes
import images from "../../Assets/Images";
import "./Pricing.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import StickyHeader from "../../Components/Common/CentiBlickHomePage/Header/StickyHeader";
import Footer from "../../Components/Common/CentiBlickHomePage/Footer/Footer";
import ComponentHeader from "../../Components/ComponentHeader/ComponentHeader";
import RingsBg from "./Components/RingsBg/RingsBg";
import OnBoarding from "../ClinicOnboarding/OnBoarding";

const Pricing = () => {
  const {
    pricingPlan,
    handleButtonClick,
    faqItems,
    openAccordionIndex,
    handleAccordionToggle,
    handleClick,
    handleClose,
    planName,
    isOpenOnboarding
  } = usePricing();
  return (
    <>
      <div>
        <StickyHeader />
        <ComponentHeader title='Plans & Pricing' />
        <div className="position-relative">
          <RingsBg />
          <div className="pricing-plans-name position-relative" style={{ padding: '90px 0px' }}>
            <div className="common-container pricing-plans-div position-relative z-2" style={{ margin: "0px auto", background: '#fff' }}>
              <div className="d-flex justify-content-center flex-column plan-details pt-4">
                <div className="d-flex justify-content-center month-btn">
                  <div className="buttons p-3">
                    <button
                      className={`month ${pricingPlan === "month" ? "active-duration" : ""}`}
                      onClick={() => handleButtonClick('month')}
                    >
                      Monthly
                    </button>
                    <button
                      className={`month d-flex align-items-center gap-2 ${pricingPlan === "year" ? "active-duration" : ""}`}
                      onClick={() => handleButtonClick('year')}
                    >
                      Yearly
                      <span>GET 2 MONTHS FREE</span>
                    </button>
                  </div>
                </div>
                <div className="year monthly">
                  {pricingPlan === "month" ? <Monthly handleClick={handleClick} /> : <Yearly handleClick={handleClick} />}
                </div>
                {/* <div className="year monthly">{isMonthly ? <Monthly /> : <Yearly />}</div> */}
              </div>
              <div className="d-flex bottom-content">
                <img src={images.PricingSideImage} alt="img" />
                <div className="content">
                  <div className="main-content">
                    <h1>Frequently asked questions</h1>
                    <p className="mt-3">
                      Everything you need to know about the product and billing.
                    </p>
                  </div>
                  <div className="side-content accordion mt-5" id="faqAccordion">
                    {faqItems.map((item, index) => (
                      <div key={index}>
                        <h2
                          className="accordion-header d-flex justify-content-between"
                          id={`faqHeading${index}`}
                        >
                          <div className="que">{item.question}</div>
                          <button
                            data-bs-toggle="collapse"
                            data-bs-target={`#faqCollapse${index}`}
                            aria-expanded={openAccordionIndex === index}
                            aria-controls={`faqCollapse${index}`}
                            onClick={() => handleAccordionToggle(index)}
                          >
                            <FontAwesomeIcon
                              icon={openAccordionIndex === index ? faMinus : faPlus}
                              style={{ color: "#516cd3" }}
                              className="icon"
                            />
                          </button>
                        </h2>
                        <div
                          id={`faqCollapse${index}`}
                          className={`accordion-collapse collapse ${openAccordionIndex === index ? "show" : ""
                            }`}
                          style={openAccordionIndex === 0 ? { height: "fit-content" } : {}}
                          aria-labelledby={`faqHeading${index}`}
                          data-bs-parent="#faqAccordion"
                        >
                          <div className="accordion-body px-0">
                            {
                              openAccordionIndex === 0 ?
                                <>
                                  <h2 className="pb-4">5 Essential Questions to Ensure Your Telehealth Platform is HIPAA Compliant</h2>
                                  <ol className="ps-4">
                                    <li>Does your telehealth platform ensure end-to-end transmission and session encryption for all communications and patient data?</li>
                                    <li>Are patient logins and provider authentication required to access telehealth sessions?</li>
                                    <li>Does your telehealth platform comply with HIPAA's Security Rule regarding data storage and transmission?</li>
                                    <li>What steps does the platform take to verify the identity and credentials of both providers and patients?</li>
                                    <li>Has your telehealth platform been updated to reflect the end of the COVID-19 Public Health Emergency, and are we compliant with current HHS guidelines?</li>
                                  </ol>
                                  <p className="pt-3">Click here for the Telehealth Compliance Whitepaper</p>
                                </>
                                :
                                item.answer
                            }
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {
        isOpenOnboarding && <OnBoarding planName={planName} handleClose={handleClose} modalOpen={isOpenOnboarding} />
      }
    </>
  );
};

export default Pricing;
