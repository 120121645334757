import React, { useEffect } from 'react'
import StickyHeader from '../../Header/StickyHeader'
import Footer from '../../Footer/Footer'
import CommonSiteMap from './CommonSiteMap'
import images from '../../../../../Assets/Images'

const Hitrust = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div>
            <StickyHeader />
            <div className='common-container'>
                <CommonSiteMap title='HITRUST' description='The HITRUST CSF (Cloud Security Framework) serves to unify security controls based on aspects of US federal law (such as HIPAA and HITECH), state law (such as Massachusetts’ Standards for the Protection of Personal Information of Residents of the Commonwealth) and recognized non-governmental compliance standards (such as PCI DSS) into a single framework that is tailored for healthcare needs.' imgUrl={images.Hitrust} />
            </div>
            <Footer />
        </div>
    )
}

export default Hitrust