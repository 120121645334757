import React, { useState, useRef, useEffect } from 'react';
import { CloseModelIcon, LandingLogo } from '../../../Assets/Svgs';

interface PermissionModalProps {
    modalOpen?: boolean;
    handleClose?: any
}

const PermissionFlowModal: React.FC<PermissionModalProps> = ({ modalOpen, handleClose }: PermissionModalProps) => {
    const [messages, setMessages] = useState([
        { text: "Let's check your audio and video to make sure you are ready for the call. Shall we get started?", fromUser: false }
    ]);
    const [showFirstButtons, setShowFirstButtons] = useState(true);
    const [showPlayButton, setShowPlayButton] = useState(false);
    const [showYesNoButtons, setShowYesNoButtons] = useState(false);
    const [showOkayButton, setShowOkayButton] = useState(false);
    const [showSecondOkayButton, setShowSecondOkayButton] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showFinalYesNoButtons, setShowFinalYesNoButtons] = useState(false);
    const [showByeButton, setShowByeButton] = useState(false);
    const [loader, setLoader] = useState<boolean>(false)

    const videoRef = useRef<HTMLVideoElement>(null);

    const handleUserMessageSend = (userText: string, botResponse: string) => {
        setMessages(prevMessages => [
            ...prevMessages,
            { text: userText, fromUser: true }
        ]);
        setTimeout(() => {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: botResponse, fromUser: false }
            ]);
        }, 500);
    };

    const handleButtonClick = (userText: string) => {
        switch (userText) {
            case "Yes, Please":
                handleUserMessageSend(userText, "First, let's test your speakers. Click Play!");
                setShowPlayButton(true);
                setShowFirstButtons(false)
                setShowYesNoButtons(false)
                setShowOkayButton(false)
                setShowSecondOkayButton(false)
                setShowVideo(false)
                setShowFinalYesNoButtons(false)
                setShowByeButton(false)
                break;
            case "No, Thanks":
                handleUserMessageSend(userText, "Okay, we can skip the check. If you change your mind, let me know.");
                break;
            default:
                handleUserMessageSend(userText, "I'm not sure how to respond to that.");
                break;
        }
    };

    const handlePlayButtonClick = () => {
        setLoader(true)
        const audio = new Audio('https://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/start.ogg');
        audio.play();

        audio.onended = () => {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: "Do you hear the sound that's playing?", fromUser: false }
            ]);
            setShowPlayButton(false);
            setShowYesNoButtons(true)
            setShowFirstButtons(false)
            setShowOkayButton(false)
            setShowSecondOkayButton(false)
            setShowVideo(false)
            setShowFinalYesNoButtons(false)
            setShowByeButton(false)
            setLoader(false)
        };
    };

    const handleYesNoAfterAudio = (userText: string) => {
        setLoader(true)
        handleUserMessageSend(userText, `Great! That means you will hear the other person.`);
        setTimeout(() => {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: "Okay, now let's check your microphone and camera.", fromUser: false }
            ]);
            setShowPlayButton(false);
            setShowYesNoButtons(false)
            setShowFirstButtons(false)
            setShowOkayButton(true)
            setShowSecondOkayButton(false)
            setShowVideo(false)
            setShowFinalYesNoButtons(false)
            setShowByeButton(false)
            setLoader(false)
        }, 1000);
    };

    const handleOkayButtonClick = () => {
        setLoader(true)
        if (!showSecondOkayButton) {
            handleUserMessageSend("Okay!", "I may need your permission to use your microphone and camera. If prompted, click Allow.");
            setShowPlayButton(false);
            setShowYesNoButtons(false);
            setShowFirstButtons(false);
            setShowOkayButton(false);
            setShowSecondOkayButton(true); // Show second Okay button
            setShowVideo(false);
            setShowFinalYesNoButtons(false);
            setShowByeButton(false);
            setLoader(false)
        } else {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(stream => {
                    handleUserMessageSend("Okay!", "Okay, it looks like I have access to your camera and microphone.");
                    setTimeout(() => {
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { text: "Say something into your microphone.", fromUser: false }
                        ]);

                        setTimeout(() => {
                            handleUserMessageSend("Sound detected!", "Excellent! I hear you loud and clear.");
                            setTimeout(() => {
                                setMessages(prevMessages => [
                                    ...prevMessages,
                                    { text: "Okay, time for the camera. Do you see a video of yourself?", fromUser: false }
                                ]);
                                setShowVideo(true);

                                if (videoRef.current) {
                                    videoRef.current.srcObject = stream;
                                    videoRef.current.play();
                                }
                                setShowSecondOkayButton(false);

                                setShowFinalYesNoButtons(true);
                                setLoader(false)
                            }, 1000);
                        }, 2000);
                    }, 1000);
                })
                .catch(err => {
                    handleUserMessageSend("Okay!", "It looks like I don't have access to your camera and microphone. Please check your permissions.");
                    console.error("🚀 ~ handleOkayButtonClick ~ err:", err)
                })
                .finally(() => {
                    // Do not set showSecondOkayButton to false here
                    // setShowSecondOkayButton(false);
                });
        }
        setShowOkayButton(false);
    };

    const handleFinalYesNoClick = (userText: string) => {
        setLoader(true)
        handleUserMessageSend(userText, "All done. Looks like your audio and video are working properly.");
        setShowPlayButton(false);
        setShowFirstButtons(false);
        setShowYesNoButtons(false);
        setShowOkayButton(false);
        setShowVideo(false);
        setShowFinalYesNoButtons(false);
        setShowByeButton(true);
        setLoader(false)
    };

    const chatRef = useRef<any>(null);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <>
            {modalOpen && (
                <div>
                    <div className="login-backdrop" />
                    <div
                        className={`modal fade ${modalOpen ? "show" : ""}`}
                        style={{ display: modalOpen ? "block" : "none" }}
                    >
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md">
                            <div className="modal-content p-0 shadow-none border-0 overflow-visible">
                                <div className="modal-body p-0">
                                    <div className='permission-modal position-relative'>
                                        <img src={CloseModelIcon} alt="Close" className='close-model-icon position-absolute' style={{ width: "20px", top: '20px', right: '20px' }} onClick={handleClose} />
                                        <div className='permission-content'>
                                            <div className='d-flex justify-content-center modal-logo'>
                                                <img src={LandingLogo} alt="" />
                                            </div>
                                            <div className='permission-chat' ref={chatRef}>
                                                {messages.map((message, index) => (
                                                    <div
                                                        key={index}
                                                        className={`message ${message.fromUser ? "user-message" : "bot-message"}`}
                                                    >
                                                        {message.text}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='permission-btns '>
                                                {showVideo && !showByeButton && (
                                                    <div className='video-preview d-flex justify-content-center'>
                                                        <video ref={videoRef} autoPlay playsInline muted />
                                                    </div>
                                                )}
                                                <div className='d-flex justify-content-between'>
                                                    {
                                                        showPlayButton && <button className='save-theme-btn mx-auto' disabled={loader} onClick={handlePlayButtonClick}>Play</button>
                                                    }
                                                    {
                                                        showYesNoButtons && (
                                                            <>
                                                                <button className='save-theme-btn' disabled={loader} onClick={() => handleYesNoAfterAudio("Yes")}>Yes</button>
                                                                <button className='save-theme-btn' disabled={loader} onClick={() => handleYesNoAfterAudio("No")}>No</button>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        (showOkayButton || showSecondOkayButton) && (
                                                            <button className='save-theme-btn mx-auto' disabled={loader} onClick={handleOkayButtonClick}>Okay!</button>)
                                                    }
                                                    {
                                                        showFinalYesNoButtons && (
                                                            <>
                                                                <button className='save-theme-btn' disabled={loader} onClick={() => handleFinalYesNoClick("Yes")}>Yes</button>
                                                                <button className='save-theme-btn' disabled={loader} onClick={() => handleFinalYesNoClick("No")}>No</button>
                                                            </>
                                                        )
                                                    }

                                                    {showByeButton && (
                                                        <button className='save-theme-btn mx-auto' onClick={handleClose}>Bye</button>
                                                    )
                                                    }
                                                    {
                                                        showFirstButtons && (
                                                            <>
                                                                <button className='save-theme-btn' onClick={() => handleButtonClick("Yes, Please")}>Yes, Please</button>
                                                                <button className='save-theme-btn' onClick={() => handleButtonClick("No, Thanks")}>No, Thanks</button>
                                                            </>
                                                        )
                                                    }
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PermissionFlowModal;
