import React, { useEffect, useState } from 'react';

// CSS
import './ResourceComponent.scss';
import images from '../../../../Assets/Images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Ring1, Ring2, Ring3 } from '../../../../Assets/Svgs';

const ResourceComponent = () => {
    const resourceData = [
        {
            image: images.News3,
            title: 'Quick examination 1',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            image: images.News2,
            title: 'Quick examination 2',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            image: images.News1,
            title: 'Quick examination 3',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            image: images.News2,
            title: 'Quick examination 4',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            image: images.News3,
            title: 'Quick examination 5',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            image: images.News1,
            title: 'Quick examination 6',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            image: images.News3,
            title: 'Quick examination 7',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        // {
        //     image: images.News1,
        //     title: 'Quick examination 8',
        //     description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        // },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationDirection, setAnimationDirection] = useState('');
    const [visibleItems, setVisibleItems] = useState<any[]>([]);
    const itemsPerPage = 3;

    const handleNext = () => {
        if (currentIndex + itemsPerPage < resourceData.length) {
            setAnimationDirection('left');
            setTimeout(() => setCurrentIndex(currentIndex + itemsPerPage), 0);
            // setCurrentIndex(currentIndex + itemsPerPage)
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setAnimationDirection('right');
            setTimeout(() => setCurrentIndex(currentIndex - itemsPerPage), 0);
            // setCurrentIndex(currentIndex - itemsPerPage)
        }
    };
    useEffect(() => {
        setVisibleItems(resourceData.slice(currentIndex, currentIndex + itemsPerPage))
    }, [currentIndex])

    return (
        <div className='resource-container position-relative'>
            <img src={Ring1} alt="" className='bg-ring-1 position-absolute' />
            <img src={Ring2} alt="" className='bg-ring-2 position-absolute' />
            <img src={Ring3} alt="" className='bg-ring-3 position-absolute' />
            <div className="common-container">
                <div className="resource-div">
                    <div className="d-flex align-items-center justify-content-between mb-5">
                        <h1>News, Resources, and Updates</h1>
                        <p>View All</p>
                    </div>
                    <div
                        className={`resource-cards row justify-content-${visibleItems.length === 1 ? 'center' : 'between'} ${animationDirection}`}
                        onAnimationEnd={() => setAnimationDirection('')}
                    >
                        {visibleItems.map((item, index) => (
                            <div key={index} className={`resource-details col-lg-4 col-md-4`}>
                                <div className="card h-100">
                                    <div className="card-image position-relative">
                                        <img src={item.image} alt={item.title} className="img-fluid" />
                                        <div className="resource-save position-absolute">Save</div>
                                    </div>
                                    <div className="card-body text-start">
                                        <h5 className="title">{item.title}</h5>
                                        <p className="description text-muted">{item.description}</p>
                                        <button
                                            className="save-theme-btn shadow-none mt-3 d-flex align-items-center gap-2"
                                            onClick={() => window.open('https://www.centiblick.com/resources', '_blank')}
                                        >
                                            Read More <FontAwesomeIcon icon={faChevronRight} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="resource-btns mt-5">
                        <button onClick={handlePrevious} disabled={currentIndex === 0}>
                            <FontAwesomeIcon icon={faArrowLeftLong} />
                        </button>
                        <button onClick={handleNext} disabled={currentIndex + itemsPerPage >= resourceData.length}>
                            <FontAwesomeIcon icon={faArrowRightLong} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResourceComponent;
