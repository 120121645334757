interface TermDetail {
    text: string;
    subDetails?: string[];
}
interface TermsData {
    title: string;
    type: 'para' | 'list';
    details: TermDetail[] | string;
    ListTitle?: string[];
    subTitle?: string;
}

interface privacyData {
    headerTitle: string;
    title?: string[] | string;
    type: 'para' | 'list';
    details: TermDetail[] | string;
    ListTitle?: string[];
    subTitle?: string[] | string;
}

export const TermsOfUseData: TermsData[] = [
    {
        title: 'Introduction',
        type: 'para',
        details: 'Welcome to CentiBlick, Inc. (“CentiBlick,” “we,” “us,” or “our”). These Terms of Use (“Terms”) govern your use of our telehealth platform, website and services (“Services”), including the access and consolidation of your medical records from various Electronic Health Record (EHR) systems via FHIR APIs. By using our Services, you agree to these Terms. If you do not agree, do not use our Services.'
    },
    {
        title: 'Services Provided',
        type: 'para',
        details: 'CentiBlick Telehealth is a healthcare platform designed to provide telehealth services with consolidated and normalized patient health data in a secure, compliant, and efficient manner. The platform operates leveraging secure cloud technologies.'
    },
    {
        title: 'User Responsibilities',
        type: 'list',
        details: [
            {
                text: 'Users agree to provide accurate and complete information when using our Services.'
            },
            {
                text: 'Users agree to use the Services only for lawful purposes and in accordance with these Terms. Prohibited uses include but are not limited to:',
                subDetails: [
                    'a. Misusing the Services for non-medical or non-compliant purposes;',
                    'b. Providing false or misleading information;',
                    'c. Attempting to gain unauthorized access to the platform or other users’ data.'
                ]
            }
        ],
        ListTitle: ['Accurate Information', 'Acceptable Use']
    },
    {
        title: 'Data Collection and Privacy',
        type: 'list',
        details: [
            {
                text: 'We collect and consolidate medical records from various EHR systems. Our Privacy Policy, which is incorporated into these Terms by reference, explains how we collect, use, and protect your data.'
            },
            {
                text: 'By using our Services, you consent to the collection and use of your medical data as described in our Privacy Policy.',
            }
        ],
        ListTitle: ['Data Handling', 'User Consent']
    },
    {
        title: 'Telehealth Services',
        type: 'list',
        details: [
            {
                text: 'Our telehealth platform facilitates virtual consultations between patients and providers, utilizing consolidated medical records for comprehensive care.'
            },
            {
                text: 'Healthcare providers using our platform must maintain patient confidentiality and adhere to medical best practices.',
            }
        ],
        ListTitle: ['Scope of Services', 'Provider Responsibilities']
    },
    {
        title: 'Intellectual Property',
        type: 'list',
        details: [
            {
                text: 'All content, software, and technology provided by CentiBlick are owned by CentiBlick or its licensors and are protected by intellectual property laws.'
            },
            {
                text: 'Users are granted a limited, non-exclusive, non-transferable license to use the Services for personal, non-commercial purposes, subject to these Terms. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any information, software, or products including the Services.',
            }
        ],
        ListTitle: ['Ownership Rights', 'License Grants']
    },
    {
        title: 'Limitation of Liability and Disclaimers',
        type: 'list',
        details: [
            {
                text: 'CentiBlick and our partners, officers, directors, employees, agents, and contractors are not liable for any indirect, incidental, special, or consequential damages arising from the use of our Services. Our total liability for any claims related to the use of our Services is limited to the amount paid by you, if any, for accessing the Services.'
            },
            {
                text: 'Our telehealth Services are not a substitute for in-person medical care when necessary. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. We do not practice medicine or render medical advice. Any medical advice or diagnosis is left to independent providers who are distinct from CentiBlick.',
            },
            {
                text: 'We make no guarantee or representation as to the suitability, reliability, timeliness, and accuracy of the information, content, and any related graphics or representations on our Services for any purpose. All such information and the content are provided on an “as is” and “as available” basis without warranty of any kind. You agree that use of these Services is at your sole risk.'
            }
        ],
        ListTitle: ['Limitation of Liability', 'Medical Disclaimers', 'No Guarantee']
    },
    {
        title: 'Termination of Services',
        type: 'list',
        details: [
            {
                text: 'CentiBlick reserves the right to terminate user accounts for violations of these Terms or misuse of the platform.'
            },
            {
                text: "Upon termination, users' access to the platform will be revoked. We will handle user data as described in our Privacy Policy.",
            }
        ],
        ListTitle: ['Termination Rights', 'Effects of Termination']
    },
    {
        title: 'Dispute Resolution',
        type: 'list',
        details: [
            {
                text: 'These Terms are governed by the laws of the state in which CentiBlick is headquartered, without regard to its conflict of law principles.'
            },
            {
                text: "Any disputes arising from these Terms or the use of our Services will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.",
            }
        ],
        ListTitle: ['Governing Law', 'Dispute Resolution Mechanism']
    },
    {
        title: 'Modification of Terms',
        type: 'list',
        details: [
            {
                text: 'We may update these Terms of Use from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated terms on our Website indicating the effective date.'
            },
            {
                text: "Continued use of the Services after any modifications constitutes acceptance of the updated Terms.",
            }
        ],
        ListTitle: ['Update Procedures', 'User Notification']
    },
    {
        title: 'Compliance with Laws',
        type: 'list',
        details: [
            {
                text: 'CentiBlick complies with relevant laws and regulations, including HIPAA and the 21st Century Cures Act, to protect patient data and ensure secure data exchange.'
            },
            {
                text: "Users must comply with all applicable laws while using our Services.",
            }
        ],
        ListTitle: ['Regulatory Compliance', 'User Compliance']
    },
    {
        title: 'Data Sharing and Disclosure',
        type: 'list',
        details: [
            {
                text: 'We share your health information with healthcare providers to facilitate telehealth services. This sharing is done in compliance with HIPAA regulations and your permission by using our Services and ensures that providers have the necessary information to deliver care.'
            },
            {
                text: 'We may share your information with third-party vendors and service providers that help us with specialized services, including billing, payment processing, customer service, business analytics, optimization, performance monitoring, hosting, and data processing. These third-party vendors and service providers may not use your information for purposes other than those related to the services.'
            },
            {
                text: 'We may disclose your information to comply with legal obligations, regulatory requirements, and government requests, and to enforce our terms and conditions and protect our rights, privacy, safety, or property, and that of our users and the public.'
            },
            {
                text: "We may share your information with other third parties when we have your explicit consent to do so.",
            }
        ],
        ListTitle: ['With Healthcare Providers', 'With Third-party Service Providers', 'For Legal and Compliance Purposes', 'With Your Consent']
    },
    {
        title: 'Data Security',
        type: 'list',
        details: [
            {
                text: 'We use AES-256 encryption for data at rest and TLS 1.2 for data in transit or equivalent.'
            },
            {
                text: "We implement role-based access controls (RBAC) to restrict access to your data to authorized personnel only.",
            },
            {
                text: "We continuously monitor our systems using AWS CloudTrail, GuardDuty, and other security tools to detect and respond to threats.",
            },
        ],
        ListTitle: ['Encryption', 'Access Controls', 'Monitoring'],
        subTitle: 'We implement robust security measures to protect your data, including:',
    },
    {
        title: 'Data Retention and Disposal',
        type: 'para',
        details: 'We retain your personal information for as long as necessary to provide our services and comply with legal obligations. When your information is no longer needed, we securely dispose of it using industry-standard methods.'
    },
    {
        title: 'Your Rights',
        type: 'list',
        details: [
            {
                text: 'You can request access to your personal information and obtain a copy of it.'
            },
            {
                text: "You can request corrections to any inaccurate or incomplete information.",
            },
            {
                text: "You can request the deletion of your personal information, subject to certain exceptions.",
            },
            {
                text: "You can request a copy of your personal information in a structured, commonly used, and machine-readable format.",
            },
            {
                text: "If you are a resident of the state of California, Virginia, Colorado, Utah, Connecticut, or Iowa, you may be afforded certain additional rights under various state privacy laws regarding our use of your personal information.",
            },
        ],
        ListTitle: ['Access', 'Correction', 'Deletion', 'Data Portability', 'Additional Rights'],
        subTitle: 'You have the following rights regarding your personal information:',
    },
    {
        title: 'Waiver and Severability',
        type: 'para',
        details: 'Failure to enforce any provision in these Terms of Use will not constitute a waiver of that provision. If any provision is held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of these Terms of Use will remain in effect.'
    },
    {
        title: 'Copyright and Trademark Notices',
        type: 'para',
        details: 'All contents of our Services are: Copyright CentiBlick, Inc. and/or its affiliates. All rights reserved. CentiBlick, Inc. and other names of CentiBlick products and/or services referenced herein are trademarks or registered trademarks of CentiBlick Inc. Other product and company names mentioned herein may be the trademarks of their respective owners.'
    },
    {
        title: 'Contact Us',
        type: 'para',
        details: 'If you have any questions or concerns about these Terms of Use, please contact us at: info@centiblick.com.'
    },
];

export const PrivacyNoticeData: any = [
    {
        headerTitle: 'Introduction',
        type: 'para',
        details: 'CentiBlick, Inc. (“CentiBlick,” “we,” “us,” or “our”) is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use the CentiBlick website or CentiBlick Telehealth Application (collectively the “Application”) and associated services hosted on AWS infrastructure. This policy is designed to ensure compliance with HIPAA, the 21st Century Cures Act, and relevant anti-blocking provisions.'
    },
    {
        headerTitle: 'Information We Collect',
        subData: [
            { title: 'Personal Information', details: ['Name, email address, phone number, and other personal details.', 'Medical history, treatment information, medication details, and other health-related information.', 'Information about how you use our Application, including IP address, device information, and interaction data.'], listTitle: ['Contact/Personal Information', 'Health Information', 'Usage Data'], reference: 'We collect personal information that you provide directly to us when you use our Application. This includes:' },
            { title: 'Automatically Collected Information', details: ['Details about your use of the Application, such as access times, pages viewed, and IP address.', 'Information about the device you use to access the Application, including hardware model, operating system, and browser type.'], listTitle: ['Log Information', 'Device Information'], reference: "We may also collect certain information automatically when you use our Application:" }
        ],
    },
    {
        headerTitle: 'How We Use Your Information',
        subData: [
            {
                details: ['Deliver telehealth services, manage your health records, and facilitate communication with healthcare providers.', 'Enhance the functionality, performance, and user experience of our Application.', 'Comply with legal and regulatory requirements, including HIPAA and the 21st Century Cures Act.', 'Implement security measures to protect your data and ensure the integrity of our systems.'],
                listTitle: ['Provide Services', 'Improve Our Services', 'Ensure Compliance', 'Protect Privacy and Security'],
                reference: 'We use your information to:'
            },
        ],
    },
    {
        headerTitle: 'Data Sharing and Disclosure',
        subData: [
            {
                title: 'With Healthcare Providers',
                reference: 'We share your health information with healthcare providers to facilitate telehealth services. This sharing is done in compliance with HIPAA regulations and ensures that providers have the necessary information to deliver care.'
            },
            {
                title: 'With Third-party Service Providers',
                reference: 'We may share your information with third-party vendors and service-providers that help us with specialized services, including billing, payment processing, customer service, business analytics, optimization, performance monitoring, hosting, and data processing. These third-party vendors and service providers may not use your information for purposes other than those related to the services.'
            },
            {
                title: 'For Legal and Compliance Purposes',
                details: ['To comply with legal obligations, regulatory requirements, and government requests.', 'To enforce our terms and conditions and protect our rights, privacy, safety, or property, and that of our users and the public.'],
                reference: 'We may disclose your information:',
                reference1: 'We may share your information with other third parties when we have your explicit consent to do so.',
            },
            {
                title: 'With Your Consent',
                reference: 'We may share your information with other third parties when we have your explicit consent to do so.'
            },
        ],
    },
    {
        headerTitle: 'Data Security',
        subData: [
            {
                details: ['We use AES-256 encryption for data at rest and TLS 1.2 for data in transit or equivalent.', 'We implement role-based access controls (RBAC) to restrict access to your data to authorized personnel only.', 'We continuously monitor our systems using AWS CloudTrail, GuardDuty, and other security tools to detect and respond to threats.'],
                listTitle: ['Encryption', 'Access Controls', 'Monitoring'],
                reference: 'We implement robust security measures to protect your data, including:'
            },
        ],
    },
    {
        headerTitle: 'Data Retention and Disposal',
        type: 'para',
        details: 'We retain your personal information for as long as necessary to provide our services and comply with legal obligations. When your information is no longer needed, we securely dispose of it using industry-standard methods.'
    },
    {
        headerTitle: 'Your Rights',
        subData: [
            {
                details: ['You can request access to your personal information and obtain a copy of it.', 'You can request corrections to any inaccurate or incomplete information.', 'You can request the deletion of your personal information, subject to certain exceptions.', 'You can request a copy of your personal information in a structured, commonly used, and machine-readable format.', 'If you are a resident of the state of California, Virginia, Colorado, Utah, Connecticut or Iowa, you may be afforded certain additional rights under various state privacy laws regarding our use of your personal information.'],
                listTitle: ['Access', 'Correction', 'Deletion', 'Data Portability', 'Additional Rights'],
                reference: 'You have the following rights regarding your personal information:'
            },
        ],
    },
    {
        headerTitle: 'Changes to This Privacy Policy',
        type: 'para',
        details: 'We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our Application and indicating the effective date.'
    },
    {
        headerTitle: 'Contact Us',
        type: 'para',
        details: 'If you have any questions or concerns about this Privacy Policy, please contact us at: info@centiblick.com'
    },
    {
        headerTitle: 'Compliance and Reporting',
        type: 'para',
        details: 'CentiBlick is committed to complying with all applicable laws and regulations, including HIPAA and the 21st Century Cures Act. We maintain detailed records of all data handling practices and ensure that our Application facilitates patient access and interoperability in compliance with anti-blocking provisions.'
    },
]

export const CookiePolicyData: any = [
    {
        headerTitle: 'Last Updated: [Insert Date]',
        details: 'At CentiBlick, we are committed to protecting your privacy and ensuring transparency in how we use cookies and similar technologies on our website. This Cookies Policy explains what cookies are, how we use them, and how you can manage your cookie preferences.'
    },
    {
        headerTitle: 'What Are Cookies?',
        subTitle: 'Cookies are small text files that are stored on your device (computer, tablet, or smartphone) when you visit a website. They help the website remember your actions and preferences over time, ensuring a better browsing experience.',
        subHeader: 'Why We Use Cookies',
        type: 'list',
        details: [
            { text: 'We use cookies to:' },
            { text: 'Enhance the functionality and performance of our website.' },
            { text: 'Provide you with a personalized experience.' },
            { text: 'Analyze website traffic to improve our services.' },
            { text: 'Serve targeted advertising based on your interests.' }
        ]
    },
    {
        headerTitle: 'Types of Cookies We Use',
        type: 'list',
        details: [
            {
                text: 'These cookies are essential for the website to function properly. Without them, you may not be able to use certain features (e.g., logging in, accessing secure areas).'
            },
            {
                text: 'These cookies collect information about how you interact with our website, such as pages visited and error messages. This helps us improve site performance and user experience.'
            },
            {
                text: 'These cookies remember your preferences (e.g., language settings, region) to provide a more personalized experience.'
            },
            {
                text: 'These cookies track your browsing habits to deliver tailored advertisements. They may also limit the number of times you see an ad and help measure the effectiveness of our campaigns.'
            },
        ],
        ListTitle: ['Strictly Necessary Cookies', 'Performance Cookies', 'Functional Cookies', 'Targeting and Advertising Cookies'],
        listType: 'number'
    },
    {
        headerTitle: 'Third-Party Cookies',
        type: 'list',
        details: [
            {
                text: 'We may also use third-party cookies from trusted partners, such as:'
            },
            {
                text: 'For example, Google Analytics, to analyze website traffic and user behavior.'
            },
            {
                text: 'To deliver personalized ads and track conversions.'
            },
            {
                text: 'These third parties have their own privacy and cookie policies, which we recommend reviewing.'
            },
        ],
        ListTitle: ['', 'Analytics Providers:', 'Advertising Partners:', ''],
    },
    {
        headerTitle: 'How to Manage Your Cookie Preferences',
        type: 'list',
        details: [
            {
                text: 'You can manage your cookie preferences at any time through our Cookie Settings page. Here, you can:'
            },
            {
                text: 'Accept all cookies.'
            },
            {
                text: 'Reject all non-essential cookies.'
            },
            {
                text: 'Customize your preferences by cookie type.'
            },
            {
                text: 'Additionally, you can control or delete cookies directly through your browser settings:',
                subDetails: [
                    'Google Chrome: Manage Cookies',
                    'Mozilla Firefox: Manage Cookies',
                    'Microsoft Edge: Manage Cookies'
                ]
            },
            {
                text: 'Please note that blocking certain cookies may impact the functionality of our website.'
            },
        ],
    },
    {
        headerTitle: 'Changes to This Policy',
        details: 'We may update this Cookies Policy from time to time to reflect changes in technology, legal requirements, or our practices. We encourage you to review this page periodically for the latest information.'
    },
    {
        headerTitle: 'Contact Us',
        subTitle: 'If you have any questions about our use of cookies or this policy, please contact us:',
        details: [
            { text: '', },
            { text: '', }
        ],
        type:'list',
        ListTitle: ['CentiBlick Support Team', 'Email: Support@CentiBlick.com'],
        listType:'none'
    },
]