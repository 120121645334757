import moment from 'moment';
import React from 'react'

const ComponentDisplay = ({ displayData }: any) => {
    const currentDate = moment().format('MMMM, YYYY');
    return (
        <div className='terms-of-use my-4 p-0'>
            {
                displayData?.map((terms: any, index: any) => (
                    <div key={index} className="security-section">
                        <div className='security-title'>
                            <h1>{
                                terms.headerTitle?.replace('[Insert Date]', currentDate)
                            }
                            </h1>
                        </div>
                        {
                            terms?.subTitle &&
                            <div className='security-para mb-2'>
                                <p>{terms.subTitle?.split(/(comprehensive online health center)/i)?.map((part: any, i: any) => (
                                    part.toLowerCase() === 'comprehensive online health center'
                                        ? <strong key={i}>{part}</strong>
                                        : part
                                ))}</p>
                            </div>
                        }
                        {
                            terms?.subHeader &&
                            <div className='mb-3 mt-5' style={{ fontSize: '24px', color: '#000', fontWeight: '600' }}>
                                {terms?.subHeader}
                            </div>
                        }
                        {terms.type === 'list' && Array.isArray(terms.details) ? (
                            <ol style={{ listStyleType: terms?.listType === 'number' ? "decimal" : terms?.listType === 'none' ? 'none' : "disc" }} className={`${terms?.listType === 'number' && 'd-flex flex-column gap-4'} ${terms?.listType === 'none' && 'ps-0'}`}>
                                {terms.details.map((item: any, subIndex: any) => (
                                    <li key={subIndex}>
                                        {terms.ListTitle && terms.ListTitle[subIndex] ? (
                                            <strong>{terms.ListTitle[subIndex]} </strong>
                                        ) : null}
                                        {terms?.listType === 'number' && <br />}
                                        {item.text.split(/(Cookie Settings)/i).map((part: any, i: any) => (
                                            part.toLowerCase() === 'cookie settings'
                                                ? <strong key={i}>{part}</strong>
                                                : part
                                        ))}
                                        {item.subDetails && Array.isArray(item.subDetails) && (
                                            <ol style={{ listStyleType: 'disc' }}>
                                                {item.subDetails.map((subItem: any, subItemIndex: any) => (
                                                    <li key={subItemIndex}>{
                                                        subItem.split(/(Manage Cookies)/i).map((part: any, i: any) => (
                                                            part.toLowerCase() === 'manage cookies'
                                                                ? <strong key={i}>{part}</strong>
                                                                : part
                                                        ))
                                                    }</li>
                                                ))}
                                            </ol>
                                        )}
                                    </li>
                                ))}
                            </ol>
                        ) : (
                            // Render a paragraph if details is a string
                            typeof terms.details === 'string' && (
                                <div className="security-para">
                                    <p>{terms.details}</p>
                                </div>
                            )
                        )}
                    </div>
                ))
            }
        </div>
    )
}

export default ComponentDisplay