import React, { useEffect } from 'react'
import StickyHeader from '../../Header/StickyHeader'
import ComponentHeader from '../../../../ComponentHeader/ComponentHeader'
import Footer from '../../Footer/Footer'
import { CookiePolicyData } from '../../../commonSecurityData'

import '../EnterpriseSecurity.scss';
import ComponentDisplay from '../ComponentDisplay'

const CookiePolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='security-and-trust'>
            <StickyHeader />
            <div>
                <ComponentHeader title="Cookies Policy" />
                <div className='common-container security-and-trust-main-div'>
                <ComponentDisplay displayData={CookiePolicyData}/>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CookiePolicy