import React from 'react'

import './Sitemap.scss'

interface CommonSiteMapProps {
    title: string
    imgUrl: string
    description: string
}
const CommonSiteMap = (props: CommonSiteMapProps) => {

    const { title, imgUrl, description } = props

    return (
        <div className='sitemap'>
            <div className='d-flex justify-content-around align-items-center sitemap-header'>
                <h1 className='title'>{title}</h1>
                <img src={imgUrl} alt={title} />
            </div>
            <div className='sitemap-desc'>
                <h1 className='sitemap-title mb-5'>{title}</h1>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default CommonSiteMap