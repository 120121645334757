import React, { useEffect } from 'react'
import StickyHeader from '../../Header/StickyHeader'
import Footer from '../../Footer/Footer'
import CommonSiteMap from './CommonSiteMap'
import images from '../../../../../Assets/Images'

const Hippa = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <StickyHeader />
      <div className='common-container'>
        <CommonSiteMap title='HIPPA' description='The Health Insurance Portability and Accountability Act of 1996 (HIPAA) is legislation that is designed to make it easier for US workers to retain health insurance coverage when they change or lose their jobs. The legislation also seeks to encourage electronic health records to improve the efficiency and quality of the US healthcare system through improved information sharing.' imgUrl={images.Hipaa} />
      </div>
      <Footer />
    </div>
  )
}

export default Hippa