import React from 'react'
import images from '../../Assets/Images'

//css
import './ComponentHeader.scss'

const ComponentHeader = ({ title }: any) => {
    return (
        <div className="component-header d-flex justify-content-center align-items-center position-relative z-2 " style={{ backgroundColor: '#fff' }}>
            <div className="first first-bg">
                {/* <img src={images.PricingBg} alt="" /> */}
            </div>
            <div>
                <h1 className="component-title">{title}</h1>
            </div>
            <div className="second second-bg">
                {/* <img src={images.PricingBg} alt="" /> */}
            </div>
        </div>
    )
}

export default ComponentHeader