import React from "react";
import { useNavigate } from "react-router-dom";

import images from "../../../../Assets/Images";

// css
import "./Footer.scss";
import moment from "moment";
import { LandingLogo } from "../../../../Assets/Svgs";

const Footer = () => {
  const navigate = useNavigate();

  const sections = [
    {
      title: "Our Platform",
      links: [
        { label: "Plans & Pricing", onClick: () => navigate("/pricing") },
        { label: "Add-On Features", onClick: () => navigate("/add-on-features") },
        { label: "Security & Trust", onClick: () => navigate("/security-and-trust") },
      ],
    },
    {
      title: "Compliance & Security",
      links: [
        { label: "HIPAA Compliance", onClick: () => navigate(`/hippa`)},
        { label: "HHS Guidelines", onClick: () => navigate(`/aicpasoc`)},
        { label: "GDPR Compliance", onClick: () => navigate(`/gdpr`)},
        { label: "SOC2 & HiTrust Certification", onClick: () => navigate(`/hi-trust`)},
        { label: "FedRAMP Certification", onClick: () => navigate(`/fedramp`)},
      ],
    },
    {
      title: "Resources",
      links: [
        { label: "News Articles", onClick: () => window.open("https://www.centiblick.com/resources/categories/telehealth-news-and-information", "_blank") },
        { label: "CentiBlick Announcements", onClick: () => window.open("https://www.centiblick.com/resources/categories/centiblick-announcements", "_blank") },
        { label: "Blog", onClick: () => window.open("https://www.centiblick.com/resources/categories/telehealth-blog", "_blank") },
      ],
    },
    {
      title: "Company",
      links: [
        { label: "About CentiBlick", onClick: () => navigate("/about-us") },
        { label: "Terms of Use", onClick: () => navigate("/terms-of-use") },
        { label: "Privacy Notice", onClick: () => navigate("/privacy-notice") },
        { label: "Cookie Settings", onClick: () => navigate("/cookie-policy") },
      ],
    },
    {
      title: "Help & Support",
      links: [
        { label: "Help Center", onClick: () => navigate("/help-and-support") },
        { label: "Technical Support", onClick: () => navigate("/contact-us") },
        { label: "FAQs", onClick: () => navigate('/faqs') },
      ],
    },
    {
      title: "Get Connected",
      links: [
        { label: "Request a Demo", onClick: () => navigate("/request-demo") },
        { label: "Contact Sales", onClick: () => navigate("/contact-sales") },
        { label: "Sign In", onClick: () => navigate("/sign-in") },
      ],
    },
  ];

  const handleMediaNavigate = (route: "linkedIn" | "faceBook" | "instagram" | "twitter") => {
    const urls = {
      linkedIn: "https://www.linkedin.com/company/centiblick/",
      faceBook: "https://www.facebook.com/CentiBlickTelehealth",
      instagram: "https://www.instagram.com/centiblick/",
      twitter: "https://x.com/CentiBlick",
    };
    window.open(urls[route], "_blank");
  };

  return (
    <div className="footer new-Footer position-relative">
      <div className="footerLogo align-items-center common-container p-0">
        <div>
          <img src={LandingLogo} alt="logo" className="logo" />
          <div className="icon">
            <img
              src={images.HomeFacebook}
              className="media"
              onClick={() => handleMediaNavigate("faceBook")}
              alt="faceBook"
            />
            <img
              src={images.HomeX}
              className="media"
              onClick={() => handleMediaNavigate("twitter")}
              alt="twitter"
            />
            <img
              src={images.HomeInstagram}
              className="media"
              onClick={() => handleMediaNavigate("instagram")}
              alt="instagram"
            />
            <img
              src={images.HomeLinkedin}
              className="media"
              onClick={() => handleMediaNavigate("linkedIn")}
              alt="linkedIn"
            />
          </div>
        </div>
        <p className="disc fw-normal">
          Compliant Telehealth <br /> That Meets <span className="fw-bold">HIPAA & HHS Guidelines</span>
        </p>
      </div>
      <div className="footerTable d-flex justify-content-between common-container">
        {sections.map((section, index) => (
          <div key={index}>
            <h1>{section.title}</h1>
            {section.links.map((link, i) => (
              <p key={i} onClick={link.onClick}>
                {link.label}
              </p>
            ))}
          </div>
        ))}
      </div>
      <p className="last">
        ©{moment().format("YYYY")} CentiBlick, Inc.{" "}
        <span
          onClick={() => window.open("https://www.summithealthportal.com/", "_blank")}
          className="cursor-pointer"
        >
          All rights reserved.
        </span>{" "}
      </p>
    </div>
  );
};

export default Footer;
