import { faCircleCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import images from '../../../../Assets/Images';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { getLocalStorage, setLocalStorage } from '../../../../Components/Patient/Utils/AuthHandlers';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../../../Components/Common/ToastMessage';
import { storeClinicalGenericOnboarding } from '../../../../Store/Thunk/Onboarding/storeClinicalGenericOnboarding';

interface formData {
    admins: Provider[];
    providers: Provider[];
}

interface Provider {
    name: string;
    email: string;
}

const ProviderStep = ({ handleBackButtonClick, handleCheckoutButtonClick }: any) => {

    const dispatch = useDispatch();
    const data = getLocalStorage('data')
    const userData = getLocalStorage('userData')

    const containerRef = useRef<any>(null);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    const [counter, setCounter] = useState(1);
    const [adminCounter, setAdminCounter] = useState(1);

    const initialValues: formData = {
        admins: [{ name: '', email: '' }],
        providers: [{ name: '', email: '' }]
    };

    const validationSchema = Yup.object().shape({
        admins: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Admin name is a required field'),
                email: Yup.string()
                    .email('Email address is not valid')
                    .required('Admin email is a required field'),
            })
        ).min(1, 'At least one admin is required'),
        providers: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Provider name is a required field'),
                email: Yup.string()
                    .email('Email address is not valid')
                    .required('Provider email is a required field'),
            })
        ).min(1, 'At least one provider is required')
    });

    const handleSubmit = async (values: formData) => {
        setLocalStorage("admin", JSON.stringify(values.admins))
        setLocalStorage("provider", JSON.stringify(values.providers))
        toast.loading('Please wait...')
        try {
            const payload = {
                account_id: userData.sub,
                email: data.email,
                first_name: data.firstName,
                last_name: data.lastName,
                provider_acknowledgement: data.provider_acknowledgement,
                terms_agreement_acknowledgement: data.terms_agreement_acknowledgement,
                admin: values.admins,
                provider: values.providers,
            }
            const response: any = await dispatch(storeClinicalGenericOnboarding({ data: payload }))
            if (response?.payload?.status === 200) {
                toast.dismiss();
                ToastMessage("success", 'Data saved successfully')
                handleCheckoutButtonClick()
            } else {
                toast.dismiss()
                ToastMessage('error', JSON.parse(response.payload.data).message)
            }
        } catch (error) {
            toast.dismiss()
            ToastMessage('error', 'Something went wrong')
        }
    };

    const handleDecrement = (type: any, formik: any) => {
        // Remove the last provider from Formik values
        if (type === 'admin') {
            if (adminCounter > 1) {
                setAdminCounter(adminCounter - 1);
                formik.setFieldValue('admins', formik.values.admins.slice(0, -1));
            }
        } else {
            if (counter > 1) {
                setCounter(counter - 1);
                formik.setFieldValue('providers', formik.values.providers.slice(0, -1));
            }
        }
    };

    const handleIncrement = (type: any, formik: any) => {
        // Add a new provider to Formik values
        if (type === 'admin') {
            setAdminCounter(adminCounter + 1);
            formik.setFieldValue('admins', [...formik.values.admins, { name: '', email: '' }]);
        } else {
            setCounter(counter + 1);
            formik.setFieldValue('providers', [...formik.values.providers, { name: '', email: '' }]);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, formik: any, type:any) => {
        const newValue = parseInt(event.target.value, 10) || 0;
        if(type === 'admin'){
            setAdminCounter(newValue);
            if (newValue > formik.values.admins.length) {
                formik.setFieldValue('admins', [...formik.values.admins, ...Array(newValue - formik.values.admins.length).fill({ name: '', email: '' })]);
            } else if (newValue < formik.values.admins.length) {
                formik.setFieldValue('admins', formik.values.admins.slice(0, newValue));
            }
        } else {
            setCounter(newValue);
            if (newValue > formik.values.providers.length) {
                formik.setFieldValue('providers', [...formik.values.providers, ...Array(newValue - formik.values.providers.length).fill({ name: '', email: '' })]);
            } else if (newValue < formik.values.providers.length) {
                formik.setFieldValue('providers', formik.values.providers.slice(0, newValue));
            }            
        }
    };

    return (
        <div className='w-100'>
            <div className='d-flex onboarding-main-flow m-0'>
                <div className='d-flex flex-column onboarding-stepping w-100' style={{ gap: "36px" }}>
                    <div className='w-100 d-flex flex-column gap-4 stepping-details'>
                        {/* <h1 className='step-heading'>Step 2</h1> */}
                        <h2 className='step-sub-heading text-center'>Step 2: Add users to your platform</h2>
                        {/* <p className='step-desc'>For an individual platform, register yourself as the provider.</p> */}
                        {/* <p className='step-desc'>For a Clinic platform, add providers and admis to setup your team. Don’t worry, registration emails will guide them through the rest!</p> */}
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            (formik) => (
                                <Form id='step1-form' className='w-100'>
                                    <div className='onboarding-step d-flex align-items-center w-100' style={{ gap: '36px' }}>
                                        <div className='w-100'>
                                            <div className='w-100 stepping-form number-of-users-div'>
                                                <label className='onboarding-user'>Who will be your primary Admin?</label>
                                                <div className="d-flex gap-2 pt-2">
                                                    <div className="input-area position-relative" onClick={() => handleDecrement('admin', formik)}>
                                                        <FontAwesomeIcon icon={faMinus} className='plus_minus_icon' />
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="d-flex align-items-center position-relative">
                                                            <input
                                                                type="text"
                                                                name="providerCount"
                                                                value={adminCounter}
                                                                onChange={(e) => handleChange(e, formik, 'admin')}
                                                                className={`input-user user-input w-100`}
                                                                style={{ paddingLeft: "30px" }}
                                                            />
                                                            <img src={images.UserIcon} alt="providers" className='user-icon' />
                                                        </div>
                                                    </div>
                                                    <button className="input-area position-relative" onClick={() => handleIncrement('admin', formik)} disabled={formik.values.admins?.length === 5}>
                                                        <FontAwesomeIcon icon={faPlus} className='plus_minus_icon' />
                                                    </button>
                                                </div>
                                                <p className='pt-2 password-suggetion'>Needs to be able to add multiple admis (max of 5) and designate a primary admin.</p>
                                                <div className='provider-counter pt-3' ref={containerRef} style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                                    <FieldArray
                                                        name="admins"
                                                        render={arrayHelpers => (
                                                            <div>
                                                                <div className='d-flex flex-column gap-3'>
                                                                    {formik.values.admins.map((admin, index) => (
                                                                        <div key={index} className="row align-items-start">
                                                                            <div className='col-lg-6 col-md-6'>
                                                                                <div className="form-group d-flex flex-column">
                                                                                    <label className='onboarding-user'>Admin name*</label>
                                                                                    <div className='position-relative'>
                                                                                        <Field
                                                                                            type="text"
                                                                                            className={`form-control w-100 ${formik.values.admins[index]?.name ? '' : 'input-user'}`}
                                                                                            name={`admins.${index}.name`}
                                                                                            placeholder='Enter name'
                                                                                        />
                                                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.admins[index]?.name ? 'check' : 'd-none'}`} />
                                                                                    </div>
                                                                                    <ErrorMessage name={`admins.${index}.name`} component="div" className="text-danger error pt-2" />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-lg-6 col-md-6'>
                                                                                <div className="form-group d-flex flex-column">
                                                                                    <label className='onboarding-user'>Admin email*</label>
                                                                                    <div className='position-relative'>
                                                                                        <Field
                                                                                            type="text"
                                                                                            className={`form-control w-100 ${formik.values.admins[index]?.email ? '' : 'input-user'}`}
                                                                                            name={`admins.${index}.email`}
                                                                                            placeholder='Enter email'
                                                                                        />
                                                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.admins[index]?.email ? 'check' : 'd-none'}`} />
                                                                                    </div>
                                                                                    <ErrorMessage name={`admins.${index}.email`} component="div" className="text-danger error pt-2" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>                                                               
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className='pt-2 number-of-users-div mb-4'>
                                                    <p className='onboarding-user'>How many providers do you have?</p>
                                                    <div className="d-flex gap-2 pt-2">
                                                        <div className="input-area position-relative" onClick={() => handleDecrement('provider', formik)}>
                                                            <FontAwesomeIcon icon={faMinus} className='plus_minus_icon' />
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="d-flex align-items-center position-relative">
                                                                <input
                                                                    type="text"
                                                                    name="providerCount"
                                                                    value={counter}
                                                                    onChange={(e) => handleChange(e, formik,'provider')}
                                                                    className={`input-user user-input w-100`}
                                                                    style={{ paddingLeft: "30px" }}
                                                                />
                                                                <img src={images.UserIcon} alt="providers" className='user-icon' />
                                                            </div>
                                                        </div>
                                                        <div className="input-area position-relative" onClick={() => handleIncrement('provider', formik)}>
                                                            <FontAwesomeIcon icon={faPlus} className='plus_minus_icon' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='provider-counter'>
                                                    {
                                                        formik.values.providers.map((provider, index) => (
                                                            <div key={index} className="row">
                                                                <div className='col-lg-6 col-md-6 pb-4'>
                                                                    <div className="form-group position-relative">
                                                                        <label className='onboarding-user'>Provider name*</label>
                                                                        <Field
                                                                            type="text"
                                                                            className={`form-control w-100 ${formik.values.providers[index]?.name ? '' : 'input-user'}`}
                                                                            name={`providers.${index}.name`}
                                                                            placeholder='Enter name'
                                                                        />
                                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.providers[index]?.name ? 'check' : 'd-none'}`} />
                                                                    </div>
                                                                    <ErrorMessage name={`providers.${index}.name`} component="div" className="text-danger error pt-2" />
                                                                </div>
                                                                <div className='col-lg-6 col-md-6 pb-4'>
                                                                    <div className="form-group position-relative">
                                                                        <label className='onboarding-user'>Provider email*</label>
                                                                        <Field
                                                                            type="text"
                                                                            className={`form-control w-100 ${formik.values.providers[index]?.email ? '' : 'input-user'}`}
                                                                            name={`providers.${index}.email`}
                                                                            placeholder='Enter email'
                                                                        />
                                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.providers[index]?.email ? 'check' : 'd-none'}`} />
                                                                    </div>
                                                                    <ErrorMessage name={`providers.${index}.email`} component="div" className="text-danger error pt-2" />
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className='d-flex justify-content-between cliniconboarding-btns w-100 pt-4'>
                                                        <button className='onboarding-back main-delete-theme-btn' onClick={handleBackButtonClick}>Back</button>
                                                        <button className='onboarding-checkout yes-theme-btn' type='submit' form='step1-form' disabled={!formik.isValid || !formik.dirty}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ProviderStep;
