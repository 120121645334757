import React, { useEffect, useState } from 'react'

//css
import './AddOnFeatures.scss'
import StickyHeader from '../Header/StickyHeader'
import images from '../../../../Assets/Images'
import ResourceComponent from '../ResourceComponent/ResourceComponent'
import Footer from '../Footer/Footer'
import CommonContactForm from '../CommonContactForm/CommonContactForm'
import AddOnForm from './AddOnForm'

const featureDetails = [
    { title: 'HIPAA Compliant Payments', details: "CentiBlick's platform offers flexible, HIPAA secure payment processing, allowing you to easily collect patient payments and tailor the payment structure to fit your practice's unique needs.", navigateUrl: '' },
    { title: '2-way EHR Integration', details: "CentiBlick's 2-way EHR integration seamlessly connects patient data between our secure telehealth platform and your electronic health records, ensuring real-time updates and streamlined clinical workflows.", navigateUrl: '' },
    { title: 'Flexcare Provider Scheduling', details: "Empowers providers to seamlessly unify location-based and online health services, offering customizable provider rules and easy booking access for patients and admins.", navigateUrl: '' },
    { title: 'my Appointments for Patients', details: "my Appointments empowers patients to easily schedule, manage, and cancel their own appointments online, reducing administrative workload and enhancing the overall patient experience.", navigateUrl: '' },
    { title: 'Customized Digital Forms', details: "CentiBlick's telehealth platform streamlines patient intake by offering fully digital forms that patients can easily fill, sign, and share. All accessible to admins through a centralized admin center.", navigateUrl: '' },
    { title: '360 Medical Records', details: "360 Medical Records by CentiBlick delivers a complete, integrated view of patient health data, empowering providers with seamless access to comprehensive medical histories from 26,000+ integrated facility EHRs.", navigateUrl: '' },
    { title: 'Auto Transcribe & AI Charting', details: "Our solution automatically converts patient interactions into accurate, structured medical records, saving time and reducing administrative burdens, so you can focus more on patient care.", navigateUrl: '' },
    { title: 'Next-gen Claims & Billing', details: "CentiBlick modernizes claims and billing processes for healthcare with a HIPAA-compliant, cutting-edge cloud environment that enables customized, efficient workflows, significantly reducing workload and time.", navigateUrl: '' },
    { title: 'Remote Patient Monitoring', details: "Our platform is pre-integrated to over 4,000 home health devices using IoT and cloud services, enabling real-time RPM with data that is easily shareable with providers and recorded for each session.", navigateUrl: '' },
    { title: 'ePrescribe', details: "CentiBlick seamlessly integrates ePrescribe services, allowing providers to electronically send prescriptions directly to pharmacies within a secure and compliant environment.", navigateUrl: '' },
]

const AddOnFeatures = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isGetStarted, setIsGetStarted] = useState<boolean>(false)
    return (
        <>
            <StickyHeader />
            <div className="add-on-coponents">
                {isGetStarted ?
                    <AddOnForm/>
                    :
                    <div className='position-relative z-2'>
                        <div className='add-on-features position-relative text-center' style={isGetStarted ? {} : { boxShadow: "0px 6px 30px 0px rgba(51, 51, 51, 0.10)" }}>
                            <img src={images.PricingBg} alt="" className='first-bg' />
                            <img src={images.PricingBg} alt="" className='second-bg' />
                            <img src={images.PricingBg} alt="" className='third-bg' />
                            <img src={images.PricingBg} alt="" className='forth-bg' />
                            <img src={images.PricingBg} alt="" className='fifth-bg' />
                            <img src={images.PricingBg} alt="" className='sixth-bg' />
                            <div className='common-container add-on-needs z-2'>
                                <h1>
                                    CentiBlick Add-On Features <br />
                                    Tailored to your Organization’s Needs
                                </h1>
                                <p>
                                    Scalable features that deliver better patient care, reduced  technology expenses and less administrative burden
                                </p>
                                <button className='save-theme-btn shadow-none' onClick={() => setIsGetStarted(true)}>Request a Quote</button>
                            </div>
                        </div>
                        <div className='all-features'>
                            <div className="common-container">
                                <div className='d-flex flex-wrap add-on-feature-details'>
                                    {
                                        featureDetails?.map((data) => (
                                            <div className='add-on-details'>
                                                <h1>{data.title}</h1>
                                                <p>{data.details}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <button className='save-theme-btn' onClick={() => setIsGetStarted(true)}>Request a Quote</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <ResourceComponent />
            </div>
            <Footer />
        </>
    )
}

export default AddOnFeatures