import React, { useEffect } from 'react'

//css
import '../ContactSales.scss'
import StickyHeader from '../../Header/StickyHeader'
import ResourceComponent from '../../ResourceComponent/ResourceComponent'
import Footer from '../../Footer/Footer'
import CommonContactForm from '../../CommonContactForm/CommonContactForm'
import { useLocation } from 'react-router-dom'

const ContactComponent = () => {

    const specialtyOption = [
        { label: 'Primary Care', value: 'Primary Care' },
        { label: 'Behavioral Health', value: 'Behavioral Health' },
        { label: 'Dermatology', value: 'Dermatology' },
        { label: 'Gastroenterology', value: 'Gastroenterology' },
    ]

    const providerOption = [
        { label: '1-5', value: '1-5' },
        { label: '6-15', value: '6-15' },
        { label: '16-50', value: '16-50' },
        { label: '51+', value: '51+' },
    ]

    const ContactFields = [
        { label: 'First Name', name: 'firstName', placeholder: 'Enter your first name', width: 'col-md-6', tickIcon: true, required:true },
        { label: 'Last Name', name: 'lastName', placeholder: 'Enter your last name', width: 'col-md-6', tickIcon: true, required:true },
        { label: 'Work Email', name: 'email', placeholder: 'Enter your email', width: 'col-md-6', type: 'email', tickIcon: true , required:true},
        { label: 'Phone Number', name: 'phoneNumber', placeholder: 'Enter phone number', width: 'col-md-6', tickIcon: true, required:true },
        { label: 'Title/Role', name: 'title', placeholder: 'Enter Title/Role', width: 'col-md-12', required:true },
        { label: 'Practice Specialty', name: 'specialty', placeholder: 'Select one', width: 'col-md-12', dropdown: true, dropdownOption: specialtyOption },
        { label: 'How Can We Help You?', name: 'comments_needs', placeholder: 'Enter Needs', width: 'col-md-12', textarea: true },
    ];
    const AddOnFields = [
        { label: 'First Name', name: 'firstName', placeholder: 'Enter your first name', width: 'col-md-6', tickIcon: true , required:true},
        { label: 'Last Name', name: 'lastName', placeholder: 'Enter your last name', width: 'col-md-6', tickIcon: true, required:true },
        { label: 'Work Email', name: 'email', placeholder: 'Enter your email', width: 'col-md-6', type: 'email', tickIcon: true , required:true},
        { label: 'Phone Number', name: 'phoneNumber', placeholder: 'Enter phone number', width: 'col-md-6', tickIcon: true, required:true },
        { label: 'Company name', name: 'companyName', placeholder: 'Enter company name', width: 'col-md-12', required:true},
        { label: 'Practice Specialty', name: 'specialty', placeholder: 'Select one', width: 'col-md-12', dropdown: true, dropdownOption: specialtyOption },
        { label: 'How Many Provider will Use Centiblick?', name: 'provider', placeholder: 'Select one', width: 'col-md-12', dropdown: true, dropdownOption: providerOption },
        { label: 'Which EHR Are you Currently Using?', name: 'ehr', placeholder: 'Enter EHR', width: 'col-md-12' },
        { label: 'Additional Comments or Needs', name: 'comments_needs', placeholder: 'Enter Comments or Needs', width: 'col-md-12', textarea: true },
    ];
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);


    return (
        <>
            <StickyHeader />
            <div className="contact-coponents">
                {
                    location.pathname === '/contact-sales' ?
                        <CommonContactForm fields={ContactFields} type='contact' />
                        :
                        <CommonContactForm fields={AddOnFields} type='addOn' />
                }
                <div className={`optimize-platform platform-started`} >
                    <ResourceComponent />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactComponent