import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// utils components
import PrivateRoutes from "./AppPrivateRoutes";
import Loader from "./Components/Common/Loader/Loader";
import routeHandlers from "./Components/routeHandler";
import Pricing from "./Pages/Pricing/Pricing";
import Investors from "./Pages/Investors/Investors";
import ClientResetPassword from "./Pages/ResetPassword/Client-ResetPassword/ClientResetPassword";
import MedicalRecord from "./Pages/Patient/MyVirtualCare/MedicalRecord/MedicalRecord";
import OnBoarding from "./Pages/OnBoarding/OnBoarding";
import ClientLogin from "./Pages/ResetPassword/Client-ResetPassword/ClientLogin";
import ClientSignup from "./Pages/ResetPassword/Client-ResetPassword/ClientSignup";
import ResetPasswordForm from "./Pages/ResetPassword/ResetPasswordForm/ResetPasswordForm";
import { setLocalStorage } from "./Components/Provider/Utils/AuthHandlers";
import { jwtDecode } from 'jwt-decode'
import ProfileFlow from "./Pages/ProfileFlow/ProfileFlow";
import ProviderProfileFlow from "./Pages/ProfileFlow/ProviderProfileFlow/ProviderProfileFlow";
import PatientProfileFlow from "./Pages/ProfileFlow/PatientProfileFlow/PatientProfileFlow";
import CommonWaiting from "./Pages/Patient/PatientLoginFlow/CommonWaiting";
import ProviderLoginFlow from "./Pages/Provider/ProviderLoginFlow/ProviderLoginFlow";
import AdminLoginScreen from "./Pages/Admin/SignInFlow/AdminLoginScreen";
import ProviderDemoSignUp from "./Pages/Provider/Home/ProviderDemo/ProviderDemoSignUp/ProviderDemoSignUp";
import ProviderDemoLogin from "./Pages/Provider/Home/ProviderDemo/ProviderDemoLogin/ProviderDemoLogin";
import AdminDemoLogin from "./Pages/Admin/AdminDemo/AdminDemoLogin";
import PatientLoginScreen from "./Pages/Patient/PatientLoginFlow/PatientLoginScreen";
import PatientDemoLoginScreen from "./Pages/Patient/DemoComponent/PatientDemoLoginFlow";
import SuccessPage from "./Pages/ClinicOnboarding/components/Platform/SuccessPage";
import ForgotPassword from "./Components/Common/ForgotPassword/ForgotPassword";
import Meeting from "./Components/Provider/UI/Common/Meeting";
import WaitingRoomPage from "./Pages/Patient/WaitingRoomPage/WaitingRoomPage";
import CentiBlickHomePage from "./Components/Common/CentiBlickHomePage/CentiBlickHomePage";
import NavigateComponent from "./Components/Common/NavigateComponent";
import ContactSales from "./Components/Common/CentiBlickHomePage/ContactSales/ContactSales";
import AddOnFeatures from "./Components/Common/CentiBlickHomePage/AddOnFeatures/AddOnFeatures";
import EnterpriseSecurity from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/EnterpriseSecurity";
import SecurityTrust from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/SecurityTrust/SecurityTrust";
import TermsOfUse from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/TermsOfUse/TermsOfUse";
import PrivacyNotice from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/PrivacyNotice/PrivacyNotice";
import HelpAndSupport from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/HelpAndSupport/HelpAndSupport";
import ContactComponent from "./Components/Common/CentiBlickHomePage/ContactSales/ContactCompo/ContactComponent";
import CookiePolicy from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/CookiePolicy/CookiePolicy";
import AboutUs from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/AboutUs/AboutUs";
import Hippa from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/SitemapPages/Hippa";
import Hitrust from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/SitemapPages/Hitrust";
import FedRAMP from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/SitemapPages/FedRAMP";
import Aicpasoc from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/SitemapPages/Aicpasoc";
import GDPR from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/SitemapPages/GDPR";
import FAQS from "./Components/Common/CentiBlickHomePage/EnterpriseSecurity/SitemapPages/FAQS";

// Auth module components
const LandingPage = lazy(() => import("./Pages/LandingPage/LandingPage"));
const SupportPage = lazy(() => import("./Pages/LandingPage/SupportPage"));
const Login = lazy(() => import("./Pages/Patient/AuthModule/Login"));
const SignUp = lazy(() => import("./Pages/Patient/AuthModule/Signup"));
const ProviderOrAdmin = lazy(() => import("./Pages/AdminOrProvider"));
const ProviderIndex = lazy(() => import("./Pages/Provider"));
const ProviderLogin = lazy(() => import("./Pages/Provider/AuthModule/login"));
const ProviderSignup = lazy(() => import("./Pages/Provider/AuthModule/signup"));
const SignUpAccountProvider = lazy(
  () => import("./Pages/Patient/AuthModule/Signup/Provider")
);
const AdminIndex = lazy(() => import("./Pages/Admin/index"));
const AdminLogin = lazy(() => import("./Pages/Admin/AuthModule/Login"));
const AdminSignup = lazy(() => import("./Pages/Admin/AuthModule/Signup"));

// common component
const CommonComponent = lazy(() => import("./Pages/Patient/Common"));
const ProviderCommon = lazy(() => import("./Pages/Provider/Common"));
const AdminCommon = lazy(() => import("./Pages/Admin/Common"));
const DemoComponent = lazy(() => import("./Pages/Patient/DemoComponent/DemoComponent"));
const ProviderDemoCommon = lazy(() => import("./Pages/Provider/Home/ProviderDemo/ProviderDemoCommon"));
const AdminDemoCommon = lazy(() => import("./Pages/Admin/AdminDemo/AdminDemoCommon"));
const SignInForm = lazy(() => import("./Components/Common/CentiBlickHomePage/Header/SignInForm"));

export default function AppRoutes() {
  useEffect(() => {
    if (window?.location?.href.includes("access_token")) {
      const accessToken = window?.location?.href.split("=")[1].split('&')[0]
      const user: any = jwtDecode(accessToken);
      setLocalStorage("userToken", accessToken);
      setLocalStorage('userData', JSON.stringify(user))
      setLocalStorage('userEmail', user?.username)
    }
  }, []);

  return (
    <Suspense fallback={<Loader />} >
      <BrowserRouter>
        <Routes>

          {/* Auth module routes */}
          <Route path="/home-page" element={<LandingPage />} />
          <Route path="/" element={<CentiBlickHomePage />} />
          <Route path="/contact-sales" element={<ContactComponent />} />
          <Route path="/request-demo" element={<ContactSales />} />
          <Route path="/contact-us" element={<ContactComponent />} />
          <Route path="/add-on-features" element={<AddOnFeatures />} />
          <Route path="/enterprise-security" element={<EnterpriseSecurity />} />
          <Route path="/security-and-trust" element={<SecurityTrust />} />
          <Route path="/hippa" element={<Hippa />} />
          <Route path="/hi-trust" element={<Hitrust />} />
          <Route path="/fedramp" element={<FedRAMP />} />
          <Route path="/aicpasoc" element={<Aicpasoc />} />
          <Route path="/gdpr" element={<GDPR />} />
          <Route path="/faqs" element={<FAQS />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-notice" element={<PrivacyNotice />} />
          <Route path="/help-and-support" element={<HelpAndSupport />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/meeting" element={<Meeting />} />
          <Route path="/meeting/:id" element={<Meeting />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/investors" element={<Investors />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/sign-in" element={<SignInForm />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/canceled" element={<h1>Payment Failed! Please try again</h1>} />
          <Route path="/login" element={<PatientLoginScreen />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/reset-password-form" element={<ResetPasswordForm />} />
          <Route path="/client/reset-password" element={<ClientResetPassword />} />
          <Route path="/client/login" element={<ClientLogin />} />
          <Route path="/client/signinup" element={<ClientSignup />} />
          <Route path="/provider/:code" element={<NavigateComponent />} />
          <Route path="/patient/:code" element={<NavigateComponent />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/waiting-room" element={<WaitingRoomPage />} />
          <Route
            path={routeHandlers["Onboarding"]["url"]}
            element={<OnBoarding />}
          />

          <Route
            path={"/patient/waiting-screen"}
            element={<PatientLoginScreen />}
          />
          <Route
            path={routeHandlers["Patient_Clinic_Profile"]["url"]}
            element={<CommonWaiting componentName="Patient_Clinic_Profile" />}
          />
          <Route
            path={routeHandlers["Patient_Clinic_Telehealth"]["url"]}
            element={<CommonWaiting componentName="Patient_Clinic_Telehealth" />}
          />
          <Route
            path={routeHandlers["Patient_Demo"]["url"]}
            element={<DemoComponent componentName="Patient_Demo" />}
          />
          <Route
            path={"/patient-demo-login"}
            element={<PatientDemoLoginScreen />}
          />
          <Route
            path={routeHandlers["Patient_Demo_Profile"]["url"]}
            element={<DemoComponent componentName="Patient_Demo_Profile" />}
          />
          <Route
            path={routeHandlers["Patient_Demo_From"]["url"]}
            element={<DemoComponent componentName="Patient_Demo_From" />}
          />
          <Route
            path={routeHandlers["Patient_Demo_Medical_History"]["url"]}
            element={<DemoComponent componentName="Patient_Demo_Medical_History" />}
          />
          <Route
            path={routeHandlers["Patient_Demo_Authorised_Consent"]["url"]}
            element={<DemoComponent componentName="Patient_Demo_Authorised_Consent" />}
          />
          <Route
            path={routeHandlers["Patient_Demo_Telehealth"]["url"]}
            element={<DemoComponent componentName="Patient_Demo_Telehealth" />}
          />
          <Route
            path={routeHandlers["Patient_Demo_Hipaa_Consent"]["url"]}
            element={<DemoComponent componentName="Patient_Demo_Hipaa_Consent" />}
          />
          <Route
            path={"/provider-demo-login"}
            element={<ProviderDemoLogin />}
          />
          <Route
            path={routeHandlers["Provider_Demo_Appointments"]["url"]}
            element={<ProviderDemoCommon componentName="Provider_Demo_Appointments" />}
          />
          <Route
            path={routeHandlers["Provider_Demo_Profile"]["url"]}
            element={<ProviderDemoCommon componentName="Provider_Demo_Profile" />}
          />
          <Route
            path={"/provider-demo-signup"}
            element={<ProviderDemoSignUp />}
          />
          <Route
            path={routeHandlers["Provider_Demo_Patient_Profile"]["url"]}
            element={<ProviderDemoCommon componentName="Provider_Demo_Patient_Profile" />}
          />
          <Route
            path={routeHandlers["Provider_Demo_Patient_Edit"]["url"]}
            element={<ProviderDemoCommon componentName="Provider_Demo_Patient_Edit" />}
          />
          <Route
            path={routeHandlers["Provider_Demo_Patient_Telehealth"]["url"]}
            element={<ProviderDemoCommon componentName="Provider_Demo_Patient_Telehealth" />}
          />
          <Route
            path={routeHandlers["Provider_Demo_Encounters"]["url"]}
            element={<ProviderDemoCommon componentName="Provider_Demo_Encounters" />}
          />
          <Route
            path={routeHandlers["Provider_Demo_Analytics"]["url"]}
            element={<ProviderDemoCommon componentName="Provider_Demo_Analytics" />}
          />
          <Route
            path={routeHandlers["Provider_Demo_Patient_Details"]["url"]}
            element={<ProviderDemoCommon componentName="Provider_Demo_Patient_Details" />}
          />
          <Route
            path={"/provider/login-screen"}
            element={<ProviderLoginFlow />}
          />
          <Route
            path={routeHandlers["Provider_SignIn_Screen"]["url"]}
            element={<ProviderCommon componentName="Provider_SignIn_Screen" />}
          />
          <Route
            path={"/admin/login-screen"}
            element={<AdminLoginScreen />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Profile"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Profile" />}
          />
          <Route
            path={routeHandlers["View_Admin_Demo_Profile"]["url"]}
            element={<AdminDemoCommon componentName="View_Admin_Demo_Profile" />}
          />
          <Route
            path={routeHandlers["Demo_Admin_Access_Room"]["url"]}
            element={<AdminDemoCommon componentName="Demo_Admin_Access_Room" />}
          />
          <Route
            path={"/admin-demo-login"}
            element={<AdminDemoLogin />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Home"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Home" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Patient_Profile"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Patient_Profile" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Patient_Details"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Patient_Details" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Patient_Edit"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Patient_Edit" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Encounters"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Encounters" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Organization_Analytics"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Organization_Analytics" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Provider_Analytics"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Provider_Analytics" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Provider_Encountres"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Provider_Encountres" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Patient_Encounters"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Patient_Encounters" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Provider_Profile"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Provider_Profile" />}
          />
          <Route
            path={routeHandlers["Add_New_Patient"]["url"]}
            element={<AdminDemoCommon componentName="Add_New_Patient" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Provider_Edit"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Provider_Edit" />}
          />
          <Route
            path={routeHandlers["Admin_Demo_Patient_Telehealth"]["url"]}
            element={<AdminDemoCommon componentName="Admin_Demo_Patient_Telehealth" />}
          />
          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/profile-flow" element={<ProfileFlow />} />
            <Route path="/provider-profile-flow" element={<ProviderProfileFlow />} />
            <Route path="/patient-profile-flow" element={<PatientProfileFlow />} />
            <Route
              path="/signup/account-provider"
              element={<SignUpAccountProvider />}
            />
            <Route path="/share-medical-record" element={<MedicalRecord />} />
            <Route
              path={routeHandlers["Patient_Meeting_Screen"]["url"]}
              element={<CommonWaiting componentName="Patient_Meeting_Screen" />}
            />

            {/* Home routes */}
            <Route
              path={routeHandlers["Home"]["url"]}
              element={<CommonComponent componentName="Home" />}
            />
            <Route
              path={routeHandlers["MyAccountInformation"]["url"]}
              element={<CommonComponent componentName="MyAccountInformation" />}
            />

            {/* ConnectMyMedicalRecords routes */}
            <Route
              path={routeHandlers["ConnectMyMedicalRecords"]["url"]}
              element={
                <CommonComponent componentName="ConnectMyMedicalRecords" />
              }
            />
            <Route
              path={routeHandlers["ConnectMyMedicalRecordsHealthSystem"]["url"]}
              element={
                <CommonComponent componentName="ConnectMyMedicalRecordsHealthSystem" />
              }
            />

            {/* ShareMyMedicalRecords routes */}
            <Route
              path={routeHandlers["ShareMyMedicalRecords"]["url"]}
              element={
                <CommonComponent componentName="ShareMyMedicalRecords" />
              }
            />
            <Route
              path={routeHandlers["ShareMyMedicalRecordsFindProviders"]["url"]}
              element={
                <CommonComponent componentName="ShareMyMedicalRecordsFindProviders" />
              }
            />
            <Route
              path={routeHandlers["ShareMyMedicalRecordsSelectProvider"]["url"]}
              element={
                <CommonComponent componentName="ShareMyMedicalRecordsSelectProvider" />
              }
            />
            <Route
              path={routeHandlers["ShareMyMedicalRecordsShareRecord"]["url"]}
              element={
                <CommonComponent componentName="ShareMyMedicalRecordsShareRecord" />
              }
            />
            <Route
              path={
                routeHandlers["ShareMyMedicalRecordsShareRecordToOtherContact"][
                "url"
                ]
              }
              element={
                <CommonComponent componentName="ShareMyMedicalRecordsShareRecordToOtherContact" />
              }
            />

            {/* Profile routes */}
            <Route
              path={routeHandlers["PatientDemographics"]["url"]}
              element={<CommonComponent componentName="PatientDemographics" />}
            />
            <Route
              path={routeHandlers["PatientDemographicsFoodSecurity"]["url"]}
              element={
                <CommonComponent componentName="PatientDemographicsFoodSecurity" />
              }
            />
            <Route
              path={routeHandlers["AllergiesAndIntolerances"]["url"]}
              element={
                <CommonComponent componentName="AllergiesAndIntolerances" />
              }
            />
            <Route
              path={routeHandlers["AllergiesAndIntolerancesDetails"]["url"]}
              element={
                <CommonComponent componentName="AllergiesAndIntolerancesDetails" />
              }
            />
            <Route
              path={routeHandlers["Vaccinations"]["url"]}
              element={<CommonComponent componentName="Vaccinations" />}
            />
            <Route
              path={routeHandlers["VaccinationsDetails"]["url"]}
              element={<CommonComponent componentName="VaccinationsDetails" />}
            />
            <Route
              path={routeHandlers["SmokingStatus"]["url"]}
              element={<CommonComponent componentName="SmokingStatus" />}
            />
            <Route
              path={routeHandlers["SmokingStatusDetails"]["url"]}
              element={<CommonComponent componentName="SmokingStatusDetails" />}
            />

            {/* Care teams routes */}
            <Route
              path={routeHandlers["CareTeams"]["url"]}
              element={<CommonComponent componentName="CareTeams" />}
            />
            <Route
              path={routeHandlers["CarePlan"]["url"]}
              element={<CommonComponent componentName="CarePlan" />}
            />
            <Route
              path={routeHandlers["CarePlanDetails"]["url"]}
              element={<CommonComponent componentName="CarePlanDetails" />}
            />
            <Route
              path={routeHandlers["CarePlanerDetails"]["url"]}
              element={<CommonComponent componentName="CarePlanerDetails" />}
            />
            <Route
              path={routeHandlers["Providers"]["url"]}
              element={<CommonComponent componentName="Providers" />}
            />
            <Route
              path={routeHandlers["ProvidersDetails"]["url"]}
              element={<CommonComponent componentName="ProvidersDetails" />}
            />

            {/* Care history routes */}
            <Route
              path={routeHandlers["Conditions"]["url"]}
              element={<CommonComponent componentName="Conditions" />}
            />
            <Route
              path={routeHandlers["ConditionsDetails"]["url"]}
              element={<CommonComponent componentName="ConditionsDetails" />}
            />
            <Route
              path={routeHandlers["Encounters"]["url"]}
              element={<CommonComponent componentName="Encounters" />}
            />
            <Route
              path={routeHandlers["EncountersDetails"]["url"]}
              element={<CommonComponent componentName="EncountersDetails" />}
            />
            <Route
              path={routeHandlers["TreatmentPlan"]["url"]}
              element={<CommonComponent componentName="TreatmentPlan" />}
            />
            <Route
              path={routeHandlers["TreatmentPlanDetails"]["url"]}
              element={<CommonComponent componentName="TreatmentPlanDetails" />}
            />

            {/* Medication routes */}
            <Route
              path={routeHandlers["Medications"]["url"]}
              element={<CommonComponent componentName="Medications" />}
            />
            <Route
              path={routeHandlers["MedicationsDetails"]["url"]}
              element={<CommonComponent componentName="MedicationsDetails" />}
            />

            {/* Procedure routes */}
            <Route
              path={routeHandlers["Procedures"]["url"]}
              element={<CommonComponent componentName="Procedures" />}
            />
            <Route
              path={routeHandlers["ProceduresDetails"]["url"]}
              element={<CommonComponent componentName="ProceduresDetails" />}
            />
            <Route
              path={routeHandlers["Devices"]["url"]}
              element={<CommonComponent componentName="Devices" />}
            />
            <Route
              path={routeHandlers["DevicesDetails"]["url"]}
              element={<CommonComponent componentName="DevicesDetails" />}
            />

            {/* Health Testing routes */}
            <Route
              path={routeHandlers["HealthScreen"]["url"]}
              element={<CommonComponent componentName="HealthScreen" />}
            />
            <Route
              path={routeHandlers["HealthScreenBars"]["url"]}
              element={<CommonComponent componentName="HealthScreenBars" />}
            />
            <Route
              path={routeHandlers["HealthScreenBarDetails"]["url"]}
              element={
                <CommonComponent componentName="HealthScreenBarDetails" />
              }
            />

            <Route
              path={routeHandlers["HealthScreenDetails"]["url"]}
              element={<CommonComponent componentName="HealthScreenDetails" />}
            />
            <Route
              path={routeHandlers["HealthScreenFilterDetails"]["url"]}
              element={
                <CommonComponent componentName="HealthScreenFilterDetails" />
              }
            />

            <Route
              path={routeHandlers["LabTest"]["url"]}
              element={<CommonComponent componentName="LabTest" />}
            />
            <Route
              path={routeHandlers["LabTestDetails"]["url"]}
              element={<CommonComponent componentName="LabTestDetails" />}
            />
            <Route
              path={routeHandlers["LabTestDetailsPercentDetails"]["url"]}
              element={
                <CommonComponent componentName="LabTestDetailsPercentDetails" />
              }
            />
            <Route
              path={routeHandlers["LabPanels"]["url"]}
              element={<CommonComponent componentName="LabPanels" />}
            />
            <Route
              path={routeHandlers["LabPanelsDetails"]["url"]}
              element={<CommonComponent componentName="LabPanelsDetails" />}
            />
            <Route
              path={routeHandlers["LabPanelsDetailsPercentDetails"]["url"]}
              element={
                <CommonComponent componentName="LabPanelsDetailsPercentDetails" />
              }
            />
            <Route
              path={routeHandlers["Vitals"]["url"]}
              element={<CommonComponent componentName="Vitals" />}
            />
            <Route
              path={routeHandlers["VitalsDetails"]["url"]}
              element={<CommonComponent componentName="VitalsDetails" />}
            />
            <Route
              path={routeHandlers["VitalsFilterDetails"]["url"]}
              element={<CommonComponent componentName="VitalsFilterDetails" />}
            />
            <Route
              path={routeHandlers["VitalsListingDetails"]["url"]}
              element={<CommonComponent componentName="VitalsListingDetails" />}
            />

            {/* My Virtual care routes */}
            <Route
              path={routeHandlers["MyVirtualCareHome"]["url"]}
              element={<CommonComponent componentName="MyVirtualCareHome" />}
            />
            <Route
              path={routeHandlers["Schedule_Appointment"]["url"]}
              element={<CommonComponent componentName="Schedule_Appointment" />}
            />
            <Route
              path={routeHandlers["Video_Chat"]["url"]}
              element={<CommonComponent componentName="Video_Chat" />}
            />
            <Route
              path={routeHandlers["MyVirtualCareProviders"]["url"]}
              element={
                <CommonComponent componentName="MyVirtualCareProviders" />
              }
            />
            <Route
              path={routeHandlers["MyVirtualCareAppointments"]["url"]}
              element={
                <CommonComponent componentName="MyVirtualCareAppointments" />
              }
            />
            {/* my forms routes */}
            <Route
              path={routeHandlers["MyForms"]["url"]}
              element={<CommonComponent componentName="MyForms" />}
            />
            <Route
              path={routeHandlers["MedicalHistory"]["url"]}
              element={<CommonComponent componentName="MedicalHistory" />}
            />
            <Route
              path={routeHandlers["HIPAAConscentFrom"]["url"]}
              element={<CommonComponent componentName="HIPAAConscentFrom" />}
            />
            <Route
              path={routeHandlers["TelehealthConsentForm"]["url"]}
              element={<CommonComponent componentName="TelehealthConsentForm" />}
            />
            <Route
              path={routeHandlers["InformationReleseAuthorization"]["url"]}
              element={<CommonComponent componentName="InformationReleseAuthorization" />}
            />
            {/* My medication routes */}
            <Route
              path={routeHandlers["MyMedicationHome"]["url"]}
              element={<CommonComponent componentName="MyMedicationHome" />}
            />
            <Route
              path={routeHandlers["MedicationDetail"]["url"]}
              element={<CommonComponent componentName="MedicationDetail" />}
            />
            <Route
              path={routeHandlers["MedicationRefillLocation"]["url"]}
              element={
                <CommonComponent componentName="MedicationRefillLocation" />
              }
            />
            <Route
              path={
                routeHandlers["MedicationDetailConfirmRefillLocation"]["url"]
              }
              element={
                <CommonComponent componentName="MedicationDetailConfirmRefillLocation" />
              }
            />
            <Route
              path={
                routeHandlers["MedicationDetailConfirmRefillLocation"]["url"]
              }
              element={
                <CommonComponent componentName="MedicationDetailConfirmRefillLocation" />
              }
            />
            <Route
              path={routeHandlers["MedicationChat"]["url"]}
              element={<CommonComponent componentName="MedicationChat" />}
            />
            <Route
              path={routeHandlers["AddNewMedication"]["url"]}
              element={<CommonComponent componentName="AddNewMedication" />}
            />
            <Route
              path={routeHandlers["MedicationConfirmationBooking"]["url"]}
              element={
                <CommonComponent componentName="MedicationConfirmationBooking" />
              }
            />
            <Route
              path={routeHandlers["MedicationCancellationBooking"]["url"]}
              element={
                <CommonComponent componentName="MedicationCancellationBooking" />
              }
            />
            {/* My connected devices routes */}
            <Route
              path={routeHandlers["MyConnectedDevices"]["url"]}
              element={<CommonComponent componentName="MyConnectedDevices" />}
            />

            <Route
              path={routeHandlers["MyWeather"]["url"]}
              element={<CommonComponent componentName="MyWeather" />}
            />

            {/* My patient EHR portals routes */}
            <Route
              path={routeHandlers["MyPatientEHRPortals"]["url"]}
              element={<CommonComponent componentName="MyPatientEHRPortals" />}
            />
            <Route
              path={routeHandlers["SearchPatientEHRPortals"]["url"]}
              element={
                <CommonComponent componentName="SearchPatientEHRPortals" />
              }
            />

            {/* My Well being routes */}
            <Route
              path={routeHandlers["MyWellBeingInitial"]["url"]}
              element={<CommonComponent componentName="MyWellBeingInitial" />}
            />
            <Route
              path={routeHandlers["MyWellBeingProvider"]["url"]}
              element={<CommonComponent componentName="MyWellBeingProvider" />}
            />
            <Route
              path={routeHandlers["MyWellBeingAdvisert"]["url"]}
              element={<CommonComponent componentName="MyWellBeingAdvisert" />}
            />
            <Route
              path={routeHandlers["MyWellBeingGoal"]["url"]}
              element={<CommonComponent componentName="MyWellBeingGoal" />}
            />
          </Route>

          {/* Provider-admin routes */}
          <Route path="/provider-or-admin" element={<ProviderOrAdmin />} />
          <Route path="/provider" element={<ProviderIndex />} />
          {/* {
            validHostnames.includes(hostname) ?
              <Route
                path={routeHandlers["Provider_Login"]["url"]}
                element={<ProviderCommon componentName="Provider_Login" />}
              />
              :
              <Route path="/provider/login" element={<ProviderLogin />} />
          } */}
          <Route path="/provider/login" element={<ProviderLoginFlow />} />
          <Route path="/provider/signup" element={<ProviderSignup />} />

          {/* Provider Home routes */}
          <Route
            path={routeHandlers["Provider_Home"]["url"]}
            element={<ProviderCommon componentName="Provider_Home" />}
          />
          {/* provider invite patient route */}
          <Route
            path={routeHandlers["Provider_InvitePatient"]["url"]}
            element={<ProviderCommon componentName="Provider_InvitePatient" />}
          />
          <Route
            path={routeHandlers["Provider_Appointments"]["url"]}
            element={<ProviderCommon componentName="Provider_Appointments" />}
          />
          <Route
            path={routeHandlers["Provider_Patient_profile"]["url"]}
            element={<ProviderCommon componentName="Provider_Patient_profile" />}
          />
          <Route
            path={routeHandlers["Provider_Encounters"]["url"]}
            element={<ProviderCommon componentName="Provider_Encounters" />}
          />
          <Route
            path={routeHandlers["Provider_Analytics"]["url"]}
            element={<ProviderCommon componentName="Provider_Analytics" />}
          />
          <Route
            path={routeHandlers["Provider_Task"]["url"]}
            element={<ProviderCommon componentName="Provider_Task" />}
          />
          <Route
            path={routeHandlers["Provider_Prescribe"]["url"]}
            element={<ProviderCommon componentName="Provider_Prescribe" />}
          />
          <Route
            path={routeHandlers["Provider_EHR"]["url"]}
            element={<ProviderCommon componentName="Provider_EHR" />}
          />
          <Route
            path={routeHandlers["Provider_Create_Task"]["url"]}
            element={<ProviderCommon componentName="Provider_Create_Task" />}
          />

          {/* Provider Profile routes */}
          <Route
            path={routeHandlers["Provider_Profile"]["url"]}
            element={<ProviderCommon componentName="Provider_Profile" />}
          />
          <Route
            path={routeHandlers["Provider_Schedule"]["url"]}
            element={<ProviderCommon componentName="Provider_Schedule" />}
          />
          <Route
            path={routeHandlers["Provider_Leave_Request"]["url"]}
            element={<ProviderCommon componentName="Provider_Leave_Request" />}
          />
          <Route
            path={routeHandlers["Provider_Reschedule"]["url"]}
            element={<ProviderCommon componentName="Provider_Reschedule" />}
          />
          {/* provider task routes */}
          <Route
            path={routeHandlers["Provider_Task_coding"]["url"]}
            element={<ProviderCommon componentName="Provider_Task_coding" />}
          />
          <Route
            path={routeHandlers["Provider_Task_Notes"]["url"]}
            element={<ProviderCommon componentName="Provider_Task_Notes" />}
          />
          <Route
            path={routeHandlers["Provider_Task_Rx"]["url"]}
            element={<ProviderCommon componentName="Provider_Task_Rx" />}
          />
          {/* Provider Consultant routes */}
          <Route
            path={routeHandlers["Provider_Consultant"]["url"]}
            element={<ProviderCommon componentName="Provider_Consultant" />}
          />
          <Route
            path={routeHandlers["Provider_Consultant_Profile"]["url"]}
            element={
              <ProviderCommon componentName="Provider_Consultant_Profile" />
            }
          />
          <Route
            path={routeHandlers["Provider_Consultant_SessionHistory"]["url"]}
            element={
              <ProviderCommon componentName="Provider_Consultant_SessionHistory" />
            }
          />
          <Route
            path={routeHandlers["Provider_Consultant_HomeDevices"]["url"]}
            element={
              <ProviderCommon componentName="Provider_Consultant_HomeDevices" />
            }
          />
          <Route
            path={routeHandlers["Provider_Consultant_Weather"]["url"]}
            element={
              <ProviderCommon componentName="Provider_Consultant_Weather" />
            }
          />
          <Route
            path={routeHandlers["Provider_Consultant_Glucode_Meter"]["url"]}
            element={
              <ProviderCommon componentName="Provider_Consultant_Glucode_Meter" />
            }
          />
          {/* Provider Video session routes */}
          <Route
            path={routeHandlers["Provider_VideoSession_Profile"]["url"]}
            element={
              <ProviderCommon componentName="Provider_VideoSession_Profile" />
            }
          />
          <Route
            path={routeHandlers["Provider_VideoSession_SessionHistory"]["url"]}
            element={
              <ProviderCommon componentName="Provider_VideoSession_SessionHistory" />
            }
          />
          <Route
            path={routeHandlers["Provider_VideoSession_Appointments"]["url"]}
            element={
              <ProviderCommon componentName="Provider_VideoSession_Appointments" />
            }
          />
          <Route
            path={routeHandlers["Provider_VideoSession_SessionDetail"]["url"]}
            element={
              <ProviderCommon componentName="Provider_VideoSession_SessionDetail" />
            }
          />

          {/* Provider Appointments routes */}
          <Route
            path={routeHandlers["Provider_Appointments_Index"]["url"]}
            element={
              <ProviderCommon componentName="Provider_Appointments_Index" />
            }
          />
          <Route
            path={routeHandlers["Provider_Appointments_Detail"]["url"]}
            element={
              <ProviderCommon componentName="Provider_Appointments_Detail" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_Appointments_ScheduleUserAppointments"][
              "url"
              ]
            }
            element={
              <ProviderCommon componentName="Provider_Appointments_ScheduleUserAppointments" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_Appointments_RescheduleRequest"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_Appointments_RescheduleRequest" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_Forms"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_Forms" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_Patient_MedicalHistory"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_Patient_MedicalHistory" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_Patient_HippaConsentForm"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_Patient_HippaConsentForm" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_Patient_TelehealthConsentForm"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_Patient_TelehealthConsentForm" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_Patient_InformationAuthorization"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_Patient_InformationAuthorization" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_AddPatient_contact"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_AddPatient_contact" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_Patient_profile"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_Patient_profile" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_DetailProfile"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_DetailProfile" />
            }
          />
          <Route
            path={
              routeHandlers["Provider_EditProfile"]["url"]
            }
            element={
              <ProviderCommon componentName="Provider_EditProfile" />
            }
          />

          {/* provider charting routes */}
          <Route
            path={routeHandlers["Provider_Charting_And_Transcription"]["url"]}
            element={<ProviderCommon componentName="Provider_Charting_And_Transcription" />}
          />
          <Route
            path={routeHandlers["Provider_Charting"]["url"]}
            element={<ProviderCommon componentName="Provider_Charting" />}
          />
          <Route
            path={routeHandlers["Provider_Charting_ERX"]["url"]}
            element={<ProviderCommon componentName="Provider_Charting_ERX" />}
          />
          <Route
            path={routeHandlers["Provider_Charting_Session"]["url"]}
            element={<ProviderCommon componentName="Provider_Charting_Session" />}
          />
          <Route
            path={routeHandlers["Provider_Charting_Auto"]["url"]}
            element={<ProviderCommon componentName="Provider_Charting_Auto" />}
          />
          <Route
            path={routeHandlers["Provider_Charting_NOMs"]["url"]}
            element={<ProviderCommon componentName="Provider_Charting_NOMs" />}
          />
          <Route
            path={routeHandlers["Provider_Charting_HCPCS"]["url"]}
            element={<ProviderCommon componentName="Provider_Charting_HCPCS" />}
          />

          {/* Admin routes */}
          <Route path="/admin" element={<AdminIndex />} />
          {/* <Route path="/admin/login" element={<AdminLogin />} /> */}
          <Route path="/admin/login" element={<AdminLoginScreen />} />
          <Route path="/admin/signup" element={<AdminSignup />} />

          {/* Admin home routes */}
          <Route
            path={routeHandlers["Admin_Home"]["url"]}
            element={<AdminCommon componentName="Admin_Home" />}
          />
          <Route
            path={routeHandlers["Admin_HomeTaskListing"]["url"]}
            element={<AdminCommon componentName="Admin_HomeTaskListing" />}
          />
          <Route
            path={routeHandlers["Admin_Profile"]["url"]}
            element={<AdminCommon componentName="Admin_Profile" />}
          />
          {/* <Route
            path={routeHandlers["Admin_AddPatient"]["url"]}
            element={<AdminCommon componentName="Admin_AddPatient" />}
          /> */}
          <Route
            path={routeHandlers["Admin_AddPatient_contact"]["url"]}
            element={<AdminCommon componentName="Admin_AddPatient_contact" />}
          />
          <Route
            path={routeHandlers["Admin_AddProvider"]["url"]}
            element={<AdminCommon componentName="Admin_AddProvider" />}
          />
          <Route
            path={routeHandlers["Admin_Schedule"]["url"]}
            element={<AdminCommon componentName="Admin_Schedule" />}
          />
          <Route
            path={routeHandlers["All_Admin_Profile"]["url"]}
            element={<AdminCommon componentName="All_Admin_Profile" />}
          />
          <Route
            path={routeHandlers["View_Admin_Profile"]["url"]}
            element={<AdminCommon componentName="View_Admin_Profile" />}
          />
          <Route
            path={routeHandlers["Admin_Scheduling"]["url"]}
            element={<AdminCommon componentName="Admin_Scheduling" />}
          />

          {/* admin scheduling */}

          <Route
            path={routeHandlers["Admin_Users"]["url"]}
            element={<AdminCommon componentName="Admin_Users" />}
          />
          <Route
            path={routeHandlers["Admin_Leave"]["url"]}
            element={<AdminCommon componentName="Admin_Leave" />}
          />
          {/* admin task management */}
          <Route
            path={routeHandlers["Admin_TaskManagement"]["url"]}
            element={<AdminCommon componentName="Admin_TaskManagement" />}
          />
          <Route
            path={routeHandlers["Admin_AddNewTaskManagement"]["url"]}
            element={<AdminCommon componentName="Admin_AddNewTaskManagement" />}
          />
          <Route
            path={routeHandlers["Admin_CreateTaskManagement"]["url"]}
            element={<AdminCommon componentName="Admin_CreateTaskManagement" />}
          />
          <Route
            path={routeHandlers["Admin_AssignTaskManagement"]["url"]}
            element={<AdminCommon componentName="Admin_AssignTaskManagement" />}
          />
          {/* Admin PatientProfile routes */}
          <Route
            path={routeHandlers["Admin_PatientProfile"]["url"]}
            element={<AdminCommon componentName="Admin_PatientProfile" />}
          />
          <Route
            path={routeHandlers["Admin_Encounters"]["url"]}
            element={<AdminCommon componentName="Admin_Encounters" />}
          />
          <Route
            path={routeHandlers["Admin_Patient_Encounters"]["url"]}
            element={<AdminCommon componentName="Admin_Patient_Encounters" />}
          />
          <Route
            path={routeHandlers["Admin_Analytics"]["url"]}
            element={<AdminCommon componentName="Admin_Analytics" />}
          />
          <Route
            path={routeHandlers["Admin_Provider_Analytics"]["url"]}
            element={<AdminCommon componentName="Admin_Provider_Analytics" />}
          />
          <Route
            path={routeHandlers["Admin_DetailProfile"]["url"]}
            element={<AdminCommon componentName="Admin_DetailProfile" />}
          />
          <Route
            path={routeHandlers["Admin_EditProfile"]["url"]}
            element={<AdminCommon componentName="Admin_EditProfile" />}
          />
          <Route
            path={routeHandlers["Admin_Forms"]["url"]}
            element={<AdminCommon componentName="Admin_Forms" />}
          />
          <Route
            path={routeHandlers["Admin_Patient_MedicalHistory"]["url"]}
            element={<AdminCommon componentName="Admin_Patient_MedicalHistory" />}
          />
          <Route
            path={routeHandlers["Admin_Patient_HippaConsentForm"]["url"]}
            element={<AdminCommon componentName="Admin_Patient_HippaConsentForm" />}
          />
          <Route
            path={routeHandlers["Admin_Patient_TelehealthConsentForm"]["url"]}
            element={<AdminCommon componentName="Admin_Patient_TelehealthConsentForm" />}
          />
          <Route
            path={routeHandlers["Admin_Patient_InformationAuthorization"]["url"]}
            element={<AdminCommon componentName="Admin_Patient_InformationAuthorization" />}
          />
          {/* provider schedule rules  */}
          <Route
            path={routeHandlers["Admin_ProviderScheduleRules"]["url"]}
            element={<AdminCommon componentName="Admin_ProviderScheduleRules" />}
          />

          {/* provider edit schedule */}
          <Route
            path={routeHandlers["Admin_EditProviderScheduleRules"]["url"]}
            element={<AdminCommon componentName="Admin_EditProviderScheduleRules" />}
          />

          {/* provider contact history rules  */}
          <Route
            path={routeHandlers["Admin_ProviderContactHistory"]["url"]}
            element={<AdminCommon componentName="Admin_ProviderContactHistory" />}
          />
          {/* Admin ProviderProfile routes */}
          <Route
            path={routeHandlers["Admin_ProviderProfile"]["url"]}
            element={<AdminCommon componentName="Admin_ProviderProfile" />}
          />
          <Route
            path={routeHandlers["Admin_Access_Room"]["url"]}
            element={<AdminCommon componentName="Admin_Access_Room" />}
          />
          <Route
            path={routeHandlers["Admin_EditProviderProfile"]["url"]}
            element={<AdminCommon componentName="Admin_EditProviderProfile" />}
          />
          {/* admin patient appointment */}
          <Route
            path={routeHandlers["Admin_Appointment"]["url"]}
            element={<AdminCommon componentName="Admin_Appointment" />}
          />
          <Route
            path={routeHandlers["Admin_Patient_Appointment"]["url"]}
            element={<AdminCommon componentName="Admin_Patient_Appointment" />}
          />
          <Route
            path={routeHandlers["Admin_Patient_Cancel_Appointment"]["url"]}
            element={<AdminCommon componentName="Admin_Patient_Cancel_Appointment" />}
          />
          <Route
            path={routeHandlers["Admin_Patient_View_Appointment"]["url"]}
            element={<AdminCommon componentName="Admin_Patient_View_Appointment" />}
          />

        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
