import React from 'react'
import images from '../../../../Assets/Images'

const step6 = ({ handleBackButtonClick, handleCheckoutButtonClick }: any) => {

  return (
    <div className='fifth-step d-flex flex-column align-items-center p-0'>
        <div className={`d-flex onboarding-display w-100`} >
          <div className='fifth-step'>
            <div className='d-flex flex-column telehealth-step mx-auto w-100' style={{ gap: "32px" }}>
              <div className='text-center d-flex flex-column gap-4'>
                <h1 className=''>Congratulations! 🎉</h1>
                <p className='success-message'>Registration emails have been sent</p>
                <p className='success-message'>We will contact you shortly to help brand your platform and schedule training if necessary</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default step6