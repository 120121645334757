import React from 'react'
import { Ring1, Ring3, Ring4 } from '../../../../Assets/Svgs'

import './RingsBg.scss'

const RingsBg = () => {
    return (
        <div className='position-absolute rings-bg h-100 w-100'>
            <img src={Ring1} alt="" className='bg-ring-1 position-absolute' />
            <img src={Ring3} alt="" className='bg-ring-2 position-absolute' />
            <img src={Ring3} alt="" className='bg-ring-3 position-absolute' />
            <img src={Ring4} alt="" className='bg-ring-4 position-absolute' />
            <img src={Ring3} alt="" className='bg-ring-5 position-absolute' />
            <img src={Ring3} alt="" className='bg-ring-6 position-absolute' />
        </div>
    )
}

export default RingsBg