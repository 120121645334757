import React from 'react'
import images from '../../../../Assets/Images'
import { useNavigate } from 'react-router-dom'

//css
import '../../../ClinicOnboarding/ClinicOnBoarding.scss'
import { LandingLogo } from '../../../../Assets/Svgs'

const SuccessPage = () => {

    const navigate = useNavigate()

    return (
        <div>
            <div className="modal-backdrop" />
            <div
                className={`modal fade show`}
                style={{ display: "block" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl`}
                    style={{ maxWidth: '880px' }}
                >
                    <div className="modal-content p-0 shadow-none border-0 overflow-visible">
                        <div className="modal-body p-0" style={{ minWidth: "fit-content" }}>
                            <div className='onboarding-page commonOnboarding' style={{ minWidth: "fit-content" }}>
                                <div className={`d-flex onboarding-display w-100`} >
                                    <div className='fifth-step'>
                                        <div className='d-flex flex-column telehealth-step mx-auto w-100' style={{ gap: "32px" }}>
                                            <div className='text-center d-flex flex-column gap-4'>
                                                <h1 className=''>Congratulations! 🎉</h1>
                                                <p className='success-message'>Registration emails have been sent</p>
                                                <p className='success-message'>We will contact you shortly to help brand your platform and schedule training if necessary</p>
                                            </div>
                                            {/* <div className='text-center'>
                                                <img src={images.MailLogo} alt="" />
                                            </div>
                                            <div className='d-flex flex-column telehealth-flow'>
                                                <div className='text-center telehealth'>
                                                    <div className='d-flex flex-column gap-3 align-items-center'>
                                                        <img src={images.authLogo} alt="" width={40} height={32} />
                                                        <div>
                                                            <span className='fifth-step-text'>You will receive an email with login instructions</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <img src={LandingLogo} alt="" className='onboard-logo cursor-pointer mt-5' onClick={() => navigate('/')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessPage