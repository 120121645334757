import React, { useEffect } from 'react'
import StickyHeader from '../../Header/StickyHeader'
import ComponentHeader from '../../../../ComponentHeader/ComponentHeader'
import Footer from '../../Footer/Footer'

import './AboutUs.scss'
import RingsBg from '../../../../../Pages/Pricing/Components/RingsBg/RingsBg'

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='security-and-trust'>
            <StickyHeader />
            <div>
                <ComponentHeader title="About us" />
                <div className='position-relative'>
                    {/* <RingsBg /> */}
                    <div className='common-container d-flex flex-column aboutUs-section position-relative z-2' style={{ gap: '60px', padding: '36px 0px' }}>
                        <div className='text-center first-section'>
                            {/* <h1 className='aboutUs-heading'>Who we are</h1> */}
                            <p className='aboutUs-desc mt-5'>At CentiBlick, we are revolutionizing healthcare through innovative telehealth solutions. Our HIPAA-compliant platform is designed to empower providers and patients alike, delivering seamless and secure virtual care experiences. By combining cutting-edge technology with user-friendly design, we’re bridging the gap between healthcare accessibility and modern digital convenience.</p>
                        </div>
                        <div className='mission-section d-flex flex-column'>
                            <div className='row'>
                                <div className='col-md-6 d-flex align-items-center flex-column' style={{ gap: '14px' }}>
                                    <h1 className='mission-header'>
                                        Our Mission
                                    </h1>
                                    <p className='aboutUs-desc text-center'>
                                        We aim to make healthcare more connected, accessible, and patient-centered. Our mission is to provide healthcare providers with the tools they need to deliver exceptional care, while giving patients a platform that simplifies managing their health and appointments.
                                    </p>
                                </div>
                                <div className='col-md-6 d-flex align-items-center flex-column' style={{ gap: '14px' }}>
                                    <h1 className='mission-header'>
                                        Our Mission
                                    </h1>
                                    <p className='aboutUs-desc text-center'>We envision a world where healthcare is no longer bound by physical barriers, enabling providers to deliver care wherever it’s needed most. By leveraging technology and innovation, we strive to make healthcare equitable, efficient, and future-ready.</p>
                                </div>
                            </div>
                            <div>
                                <h1 className='aboutUs-heading text-center mb-3'>Why Choose CentiBlick?</h1>
                                <div className="row">
                                    <div className="col-md-4">
                                        <h1 className='mission-header w-100' style={{ fontSize: '20px' }}>Security You Can Trust</h1>
                                        <p className="aboutUs-desc text-center mt-3">Built with HIPAA and HHS compliance in mind, our platform ensures patient data is protected at every step.</p>
                                    </div>
                                    <div className="col-md-4">
                                        <h1 className='mission-header w-100' style={{ fontSize: '20px' }}>Tailored Support</h1>
                                        <p className="aboutUs-desc text-center mt-3">From onboarding to everyday use, our team is here to help you succeed.</p>
                                    </div>
                                    <div className="col-md-4">
                                        <h1 className='mission-header w-100' style={{ fontSize: '20px' }}>Forward-Thinking Technology</h1>
                                        <p className="aboutUs-desc text-center mt-3">We stay ahead of the curve, integrating the latest advancements like AI-driven features and secure scheduling.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex aboutUs-bottom-content' style={{ gap: '60px' }}>
                            <div className='w-100'>
                                <h1 className='aboutUs-heading'>What We Do</h1>
                                <p className='aboutUs-desc my-5'>CentiBlick is more than just a telehealth platform—it's a <strong>comprehensive online health center</strong> for your practice. We offer:</p>
                                <ol className='ps-4' style={{ listStyle: 'disc' }}>
                                    <li className='aboutUs-desc'><strong>Patient Health Management:</strong>Empower patients to manage their profiles, medical records, and appointments securely.</li>
                                    <li className='aboutUs-desc'><strong>Customizable Solutions:</strong>Tailor our platform to match the unique needs of your practice.</li>
                                    <li className='aboutUs-desc'><strong>HIPAA-Compliant Virtual Care:</strong>Ensure secure and compliant telehealth sessions.</li>
                                    <li className='aboutUs-desc'><strong>Scalable Features:</strong>Grow with your practice while maintaining reliability and efficiency.</li>
                                    <li className='aboutUs-desc'><strong>API Integrations:</strong>Enhance your experience with advanced features like AI-powered patient avatars.</li>
                                </ol>
                            </div>
                            <div className='w-100'>
                                <h1 className='aboutUs-heading'>Our Journey</h1>
                                <p className='aboutUs-desc mt-5'>CentiBlick was founded by a passionate team of healthcare and technology professionals who recognized the need for a better telehealth solution. Since our inception, we’ve partnered with innovative providers and practices, driving better patient outcomes and empowering clinics to thrive in the digital age.</p>
                                <h1 className='aboutUs-heading' style={{ marginTop: '60px' }}>Get in Touch</h1>
                                <p className='aboutUs-desc mt-5'>Have questions or want to learn more? Contact us today.
                                    <br />
                                    Email: <strong style={{ color: '#516cd3' }}>Support@centiblick.com</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs