/* eslint-disable */
import React, { useEffect, useState } from "react";
import i18n from "./Language/i18n";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "./Store/Slicers/commonSlicer";

// component
import AppRoutes from "./AppRoutes";

// aws imports
import { Amplify } from "aws-amplify";

// config
import { AwsConfig } from "./awsConfig";

// assets
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Assets/Css/style.css";
import "./Assets/Css/common.css";
import { RootState } from "./Store";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WebSocketProvider } from "./SocketConnection/WebSocketContext";
import {
  MeetingProvider,
  lightTheme,
  GlobalStyles
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import { Ring1, Ring2, Ring3 } from "./Assets/Svgs";
import { ToastMessage } from "./Components/Common/ToastMessage";
import { Elements } from "@stripe/react-stripe-js";
// Amplify.configure({
//   Auth: {
//     region: AwsConfig.REGION,
//     userPoolId: AwsConfig.USER_POOL_ID,
//     userPoolWebClientId: AwsConfig.USER_POOL_CLIENT_ID,
//   },
// });

function App() {
  const { themeColor, language } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch();

  const landscape = window.matchMedia("(orientation: landscape)").matches;

  useEffect(() => {
    if (language !== undefined) {
      dispatch(setLanguage(language));
      i18n.changeLanguage(language);
    } else {
      dispatch(setLanguage("en"));
      i18n.changeLanguage("en");
    }
  }, [language]);

  return (
    <>
      <Elements stripe={null}>
        <ThemeProvider theme={lightTheme}>
          <GlobalStyles />
          <MeetingProvider>
            <WebSocketProvider>
              <div data-theme={themeColor}>
                <ToastContainer />

                {(window.innerWidth <= 767 && landscape) || (window.innerWidth > 767 && !landscape) ? (
                  <div className="landscape-message">
                    <img src={Ring1} alt="" className='bg-ring-1 position-absolute' />
                    <img src={Ring2} alt="" className='bg-ring-2 position-absolute' />
                    <img src={Ring3} alt="" className='bg-ring-3 position-absolute' />
                    <div className="landscape-msg-div text-center position-relative">
                      <h1 className="mb-4">
                        Portrait Detected
                      </h1>
                      <p>
                        This content is best experienced with your device in <b>Landscape Mode</b>. Please rotate your device for optimal display.
                      </p>
                    </div>
                  </div>
                ) : (
                  <AppRoutes />
                )}
              </div>
            </WebSocketProvider>
          </MeetingProvider>
        </ThemeProvider>
      </Elements>
    </>
  );
}

export default App;
