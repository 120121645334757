import React, { useEffect } from 'react'
import StickyHeader from '../../Header/StickyHeader'
import Footer from '../../Footer/Footer'
import CommonSiteMap from './CommonSiteMap'
import images from '../../../../../Assets/Images'

const GDPR = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div>
            <StickyHeader />
            <div className='common-container'>
                <CommonSiteMap title='GDPR' description='The European Union’s General Data Protection Regulation (GDPR) protects European Union (EU) individuals’ fundamental right to privacy and the protection of personal data. The GDPR includes robust requirements that raise and harmonize standards for data protection, security, and compliance.' imgUrl={images.GDPR} />
            </div>
            <Footer />
        </div>
    )
}

export default GDPR