import React, { useState } from "react";
import images from "../../../Assets/Images";

const Yearly = ({ handleClick }: any) => {

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleMouseEnter = (index: any) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(-1); // Set the default active index when leaving
  };
  return (
    <div className="container">
      <div className="row mx-auto d-flex justify-content-center">
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-md-3 plans-details">
          <div
            onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave} className={`essential w-100 d-flex justify-content-between flex-column ${activeIndex === 0 ? 'active-plan' : ''}`}
          >
            <div className="top">
              <h1 className="header">INDIVIDUAL PROVIDER</h1>
              <div className="price-details">
                <h1 className="price">
                  $350<span>/year</span>
                </h1>
                <p>Unlimited use</p>
              </div>
              <div className="details">
                <p className='heading mb-2 ms-0 invisible'>Individual Provider +</p>
                <div className='d-flex'>
                  <img src={images.Icon} alt='icon' />
                  <p className='d-inline'>Secure Encrypted Appointment Links</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt='icon' />
                  <p className='d-inline'>Access Control & Authentication</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt='icon' />
                  <p className='d-inline'>Compliant Provider Registration</p>

                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt='icon' />
                  <p className='d-inline'>Transmission & Session Encryption</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt='icon' />
                  <p className='d-inline'>Teleconsent</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt='icon' />
                  <p className='d-inline'>Customized Branding</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt='icon' />
                  <p className='d-inline'>Immediate platform access</p>
                </div>
              </div>
            </div>
            {activeIndex === 0 ?
              <div className='contact' onClick={() => handleClick("individual")}>
                Buy Now
              </div> :
              <div className='get-btn'>
                Buy Now
              </div>}
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-md-3 plans-details">
          <div onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave} className={` essential w-100 d-flex justify-content-between flex-column h-100 ${activeIndex === 1 ? 'active-plan' : ''}`}>
            <div className="top">
              <h1 className='header'>CLINIC PLATFORM</h1>
              <div className='price-details'>
                <h1 className='price'>$500<span>/Provider/year</span></h1>
                <p>Unlimited use</p>
              </div>
              <div className='details'>
                <p className='heading mb-2 ms-0'>Individual Provider +</p>

                {/* <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Practice Website Integration</p>
                                    </div> */}
                <div className='d-flex'>
                  <img src={images.Icon} alt="icon" />
                  <p className='d-inline'>Admin Center</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt="icon" />
                  <p className='d-inline'>Unlimited Admins</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt="icon" />
                  <p className='d-inline'>Patient & Provider Profiles</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt="icon" />
                  <p className='d-inline'>Provider Room Directory</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt="icon" />
                  <p className='d-inline'>Org & Provider Encounters</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt="icon" />
                  <p className='d-inline'>Org & Provider Analytics</p>
                </div>
                <div className='d-flex'>
                  <img src={images.Icon} alt='icon' />
                  <p className='d-inline'>Immediate platform access</p>
                </div>
              </div>
            </div>
            {activeIndex === 1 ?
              <div className='contact' onClick={() => handleClick("clinic")}>
                Buy Now
              </div> :
              <div className='get-btn'>
                Buy Now
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Yearly;
