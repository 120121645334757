/* eslint-disable global-require */
const images: any = {
  //videos
  PatientVideo: require("../Videos/PatientVideo.mov"),
  ProviderVideo: require("../Videos/ProviderVideo.mov"),
  CopyToClipBoardImage: require("./CopyToClipBoardLogo.png"),
  //common images
  Encounters: require('./Encounters.png'),
  Analytics: require('./Analytics.png'),
  DefaultImage: require('./ProfileDefault.png'),
  InfoGray: require('./InfoGray.png'),
  LaptopProfile: require('./LaptopProfile.png'),
  LaptopPlan: require('./LaptopPlan.png'),
  SelectInfo: require('./SelectInfo.png'),
  OnboardingBG: require('./OnboardingBG.png'),
  CHPortalHybrid: require('./CHPortalHybrid.png'),
  OnBoardingSideImage: require('./OnBoardingSideImage.png'),
  Lock: require('./lock.png'),
  GPayLogo: require('./GPayLogo.png'),
  VisaLogo: require('./VisaLogo.png'),
  DiscoverLogo: require('./DiscoverLogo.png'),
  PaymentMethodIcon: require('./PaymentMethodIcon.png'),
  ChatLoader: require('./ChatLoader.gif'),
  LoaddingLoader: require('./ChatLoader1.gif'),
  ChatLine: require('./chatline.png'),
  VidiconLine: require('./vidiconline.png'),
  ChatLineWhite: require('./chatlineWhite.png'),
  VidiconLineWhite: require('./vidiconlineWhite.png'),
  PatientService: require('./PatientService.png'),
  CalenderNewLogo: require('./CalenderNewLogo.png'),
  ServiceInfo: require('./ServiceInfo.png'),
  CalenderLogo: require("./CalendarEventLogo.png"),
  ChatLogo: require('./chatLogo.png'),
  CupLine: require("./CupLineLogo.png"),
  CopyLogo: require("./CopyLogo.png"),
  EditIcon: require("./edit-line.png"),
  ClockThree: require("./time-line.png"),
  ClockThreeWhite: require('./time-line-white.png'),
  shadedLogo: require("./shadedLogo.png"),
  ChatLineIcon: require("./ChatLineIcon.png"),
  mailSendLineLogo: require("./MailSendLineLogo.png"),
  messagesOutlineLogo: require("./messagesOutlineLogo.png"),
  InvestorsRevenue: require("./InvestorsRevenue.png"),
  InvestorsOutlook: require("./InvestorsOutlook.png"),
  eyeIcon: require("./eye-line.png"),
  profileLogo: require("./profileLogo.png"),
  scheduleLogo: require("./scheduleLogo.png"),
  BorderLogo: require("./logoborder.png"),
  PriceBackground: require("./PriceBackground.png"),
  PricingBg: require("./PricingBg.png"),
  PlusIcon: require("./PlusIcon.png"),
  PricingSideImage: require("./PricingSideImage.png"),
  Icon: require("./Icon.png"),
  UserIcon: require("./UserImageIcon.jpg"),
  MailLogo: require("./MailLogo.png"),
  FilledCheckMark: require('./FiiledCheckedCircle.png'),
  Share: require("./Share.png"),
  EditLineFilled: require('./editLineFilled.png'),
  ShareFilled: require("./shareLineFiilled.png"),
  Transcription: require("./Trascription.png"),
  Chart: require("./Chart.png"),
  Logo: require("./Logo.png"),
  LaptopSideImage: require("./LaptopSideImage.png"),
  Notch: require("./Notch.png"),
  FileShield: require("./file-shield.png"),
  TeamMeetingImage: require("./provider-Emily.png"),
  LandingPageFooterLogo: require("./LandingPageFooterLogo.png"),
  LandingPageSideImage: require("./LandingPageSideImage.png"),
  LandingPageImage: require("./MacbookAir.png"),
  LandingBackground: require("./landingBackground.png"),
  GrewDownArroWIcon: require("./greyarrow.png"),
  VideoEndCall: require("./videoEndCall.png"),
  VideoCalender: require("./VideoCalender.png"),
  VideoMessage: require("./VideoMessage.png"),
  VideoSync: require("./VideoSync.png"),
  VideoSpeaker: require("./VideoSpeaker.png"),
  CalenderIcon: require("./calender.png"),
  BlackDownArrowIcon: require("./BlackDownArrow.png"),
  whieDownArrowIcon: require("./whiteDownArrow.png"),
  downFilledIcon: require("./DownFilledIcon.png"),
  menuIcon: require("./MenuIcon.png"),
  actonHospital: require("./AkronHospital.png"),
  plusLogo: require("./plus-icon.png"),
  leftArrow: require("./leftArrow.png"),
  Google: require("./google.png"),
  authPower: require("./auth-power.png"),
  sidebarUser: require("./sidebar-profile.png"),
  profileDetailImg: require("./profile-detail-img.png"),
  search: require("./search.png"),
  powerImageMobile: require("./power-img-mobile.png"),
  mercyurgent: require("./mercyurgent.png"),
  greyArrow: require("./grey-arrow.png"),
  searchBlack: require("./searchblack.png"),
  exclamationIcon: require("./exclamation-img.png"),
  barcode: require("./barcode.png"),
  blueScale: require("./blue-scale.png"),
  yellowScale: require("./yellow-scale.png"),
  pinkScale: require("./pink-scale.png"),
  hsDetailImg: require("./hs-detail-img.png"),
  exclamation: require("./exclamation.png"),
  commentIcon: require("./comment.png"),
  hsInnerListImage: require("./hs-inner-list-img.png"),
  centiblick: require("./centiblick.png"),
  providerKelly: require("./provider-kelley.png"),
  providerJill: require("./provider-jill.png"),
  providerJohn: require("./provider-john.png"),
  providerJana: require("./provider-jana.png"),
  providerMyra: require("./provider-myra.png"),
  providerMarie: require("./provider-marie.png"),

  whiteArrow: require("./Arrow-white.png"),
  timeHeaderImg: require("./timepicker-head.png"),
  searchWhite: require("./search-white.png"),
  cancelBtn: require("./cancelbtn.png"),
  saveBtn: require("./savebtn.png"),
  sidebarJack: require("./sidebarJack.png"),
  sidebarJane: require("./sidebarJane.png"),
  sidebarJames: require("./sidebarJames.png"),
  sidebarKali: require("./sidebarKali.png"),
  InSessionUser: require("./InSessionUser.png"),
  glucoseMonitor: require("./glucose-monitor.png"),
  scale: require("./scale.png"),
  oximeter: require("./oximeter.png"),
  stethoScope: require("./stethoscope.png"),
  medicalNotes: require("./medical_notes.png"),
  walmart: require("./walmart.png"),
  mw: require("./m_w.png"),
  costco: require("./m_costo.png"),
  cvs: require("./csv.png"),
  sams: require("./m_sams.png"),
  glucoseMonitorConnected: require("./glucoseMonitor.png"),
  scaleMonitorConnected: require("./scaleMonitor.png"),
  // blue theme images
  authLogo: require("./auth-logo.png"),
  authBg: require("./auth-bg.png"),
  authImage: require("./auth-img.png"),
  ProviderLogo: require('./ProviderLogo.png'),
  languageIcon: require("./language-icon.png"),
  FB: require("./fb.png"),
  sidebarLogo: require("./dash-logo.png"),
  sidebarGraph: require("./graph.png"),
  sidebarUpload: require("./sidebar-upload.png"),
  sidebarReply: require("./sidebar-reply.png"),
  navProfile: require("./nav-profile.png"),
  navCareteams: require("./nav-careterms.png"),
  navCareHistory: require("./nav-carehistory.png"),
  navMedications: require("./nav-mediacations.png"),
  navProcedures: require("./nav-procedures.png"),
  navHealthTesting: require("./nav-health-testing.png"),
  navLearningHealthSystem: require("./nav-learning-health.png"),
  navGoal: require("./nav-learning-health.png"),
  listMedication: require("./list-medication.png"),
  listStethoscope: require("./list-stetho.png"),
  listDoctor: require("./list-doctor.png"),
  listWatch: require("./list-watch.png"),
  listPreventDiabetes: require("./list-preventDiabietes.png"),
  listDiabetes: require("./list-diabetes.png"),
  rightArrow: require("./right-arrow.png"),
  CHCare: require("./ch-care.png"),
  CHMedication: require("./ch-medication.png"),
  CHForms: require("./FormsLogo.png"),
  CHCareLogo: require("./CHcarelogo.png"),
  CHPatientPortalLogo: require("./CHPatientPortalLogo.png"),
  CHDevicesLogo: require("./CHDevicesLogo.png"),
  CHDevices: require("./ch-devices.png"),
  CHPortal: require("./carousal3.png"),
  CHRecords: require("./ch-records.png"),
  Down: require("./down.png"),
  CarePlan: require("./care-plan.png"),
  HealthScreen: require("./HealthScreen.png"),
  labTest: require("./lab-test-listing-img.png"),
  labPanel: require("./lab-panels-listing-img.png"),
  chatIcon: require("./chat-img.png"),
  hsImgListItem: require("./health-img-list.png"),
  vitalsBMI: require("./bmi-blue.png"),
  vitalsBP: require("./bp-blue.png"),
  vitalsWeight: require("./weight-blue.png"),
  vitalsRespiratory: require("./respiratory.png"),
  vitalsOxygen: require("./oxygen-blue.png"),
  vitalsTest: require("./vitaltest-blue.png"),
  signupSuccess: require("./signup-success.png"),
  visitNow: require("./visit-icon.png"),
  appointment: require("./appointment-icon.png"),
  visitNowActive: require("./visitnow-blue.png"),
  appointmentActive: require("./appointment-blue.png"),
  filterImg: require("./Filters.png"),
  timeRightBlue: require("./time-head-right-blue.png"),
  providerLogin: require("./providerlogin.png"),
  adminLogin: require("./adminlogin.png"),
  medicine1: require("./medicine1.png"),
  medicine2: require("./medicine2.png"),
  wellDocLogo: require("./welldoc-logo.png"),
  wellDocSmallLogo: require("./welldoc-small-logo.png"),
  goalProfileFood: require("./goal-profile-food.png"),
  goalProfile: require("./goal-profile.png"),
  lockClient: require("../Images/lock.png"),
  amazonfillClient: require("../Images/amazon-fill.png"),
  applefillClient: require("../Images/apple-fill.png"),
  facebookClient: require("../Images/facebook-circle-fill.png"),
  googleClient: require("../Images/google-fill.png"),
  //Patient images

  PatientSidebarArrow: require('./PatientSidebarArrow.png'),
  PatientSidebarCloud: require('./PatientSidebarCloud.png'),



  // provider images
  ProviderQuay: require('./ProviderQuay.png'),
  ProviderMarco: require('./ProviderMarco.png'),
  ProviderRosa: require('./ProviderRosa.png'),
  ProviderJulie: require('./ProviderJulie.png'),
  providerSidebarUser: require("./provider-siderbar-user.png"),
  providerAppointments: require("./providerAppointments.png"),
  providerTasks: require("./providerTasks.png"),
  providerTasksSquare: require("./providerTasksSquare.png"),
  providerEHR: require("./providerEHR.png"),
  providerEHRSquare: require("./providerEHRSquare.png"),
  providerPrescribe: require("./providerPrescribe.png"),
  providerPrescribeSquare: require("./providerPrescribeSquare.png"),
  providerReffarals: require("./refferralslogo.png"),
  providerWelcome: require("./providerWelcome.png"),
  appointmentImage: require("./appointment.png"),

  //Admin iamges
  adminHomePamel: require("./provider-pamela.png"),
  carouselPatientProfile: require("./patient-profile.png"),
  carouselProviderProfile: require("./provider-profile.png"),
  carouselSchedule: require("./Scheduling.png"),
  carouselMetricsPlanning: require("./matrics-planning.png"),
  carouselForecasting: require("./Forecasting.png"),
  carouselTaskManagement: require("./taskmanagement.png"),
  carouselPatientAppointments: require("./PatientAppointments.png"),
  carouselProviderScheduling: require("./ProviderScheduling.png"),
  carouselPatientReferrals: require("./PatientReferrals.png"),

  // parrot theme images
  parrotAuthLogo: require("./parrot-auth-logo.png"),
  parrotAuthBg: require("./parrot-auth-bg.png"),
  parrotAuthImage: require("./parrot-auth-image.png"),
  parrotLanguageIcon: require("./parrot-language-icon.png"),
  parrotFB: require("./parrotfb.png"),
  parrotSidebarLogo: require("./parrot-dash-logo.png"),
  parrotSidebarGraph: require("./parrot-graph.png"),
  parrotSidebarUpload: require("./sidebar-parrot-upload.png"),
  parrotSidebarReply: require("./sidebar-parrot-reply.png"),
  parrotNavProfile: require("./nav-profile-parrot.png"),
  parrotNavCareteams: require("./nav-careterms-parrot.png"),
  parrotNavCareHistory: require("./nav-carehistory-parrot.png"),
  parrotNavMedications: require("./nav-medications-parrot.png"),
  parrotNavProcedures: require("./nav-procedures-parrot.png"),
  parrotNavHealthTesting: require("./nav-health-testing-parrot.png"),
  parrotNavLearningHealthSystem: require("./nav-learning-health-parrot.png"),
  parrotNavGoal: require("./nav-learning-health-parrot.png"),
  parrotListMedication: require("./list-medication-parrot.png"),
  parrotListStethoscope: require("./list-stetho.png"),
  parrotListDoctor: require("./list-doctor-parrot.png"),
  parrotListWatch: require("./list-watch.png"),
  ParrotListDiabetes: require("./list-diabetes.png"),
  ParrotRightArrow: require("./right-arrow-parrot.png"),
  ParrotCHCare: require("./ch-care-parrot.png"),
  ParrotCHMedication: require("./ch-medication-parrot.png"),
  ParrotCHDevices: require("./ch-devices-parrot.png"),
  ParrotCHPortal: require("./ch-portal-parrot.png"),
  ParrotCHRecords: require("./ch-records-parrot.png"),
  ParrotDown: require("./parrot-down.png"),
  CarePlanParrot: require("./care-plan-parrot.png"),
  parrotHealthScreen: require("./parrotHealthScreen.png"),
  parrotLabTest: require("./parrot-lab-test-listing-img.png"),
  parrotLabPanel: require("./parrot-lab-panels-listing-img.png"),
  parrotChatIcon: require("./parrot-chat-img.png"),
  hsImgListItemParrot: require("./health-img-list-parrot.png"),
  vitalsBMIParrot: require("./bmi-parrot.png"),
  vitalsBPParrot: require("./bp-parrot.png"),
  vitalsWeightParrot: require("./weight-parrot.png"),
  vitalsRespiratoryParrot: require("./respiratory-parrot.png"),
  vitalsOxygenParrot: require("./oxygen-parrot.png"),
  vitalsTestParrot: require("./vitaltest-parrot.png"),
  signupSuccessParrot: require("./signup-success.png"),
  parrotVisitNow: require("./visit-icon-parrot.png"),
  parrotAppointment: require("./appoinment-icon-parrot.png"),
  parrotVisitNowActive: require("./visitnow-parrot.png"),
  parrotAppointmentActive: require("./appointment-parrot.png"),
  parrotFilterImg: require("./Filters-parrot.png"),
  timeRightParrot: require("./time-head-right-parrot.png"),
  parrotProviderLogin: require("./providerlogin.png"),
  parrotAdminLogin: require("./adminlogin.png"),
  parrotMedicine1: require("./medicine1.png"),
  parrotMedicine2: require("./medicine2.png"),
  wellDocParrot: require("./wellDoc.png"),
  wellDocLogoParrot: require("./welldoc-logo.png"),
  // provider images
  parrotProviderSidebarUser: require("./provider-siderbar-user.png"),
  parrotProviderAppointments: require("./providerAppointments.png"),
  parrotProviderTasks: require("./providerTasks.png"),
  parrotProviderEHR: require("./providerEHR.png"),
  parrotProviderPrescribe: require("./providerPrescribe.png"),
  parrotProviderWelcome: require("./providerWelcome.png"),
  parrotAppointmentImage: require("./appointment.png"),

  // Admin Images
  ParrotAdminHomePamel: require("./provider-pamela.png"),
  parrotCarouselPatientProfile: require("./patient-profile.png"),
  parrotCarouselProviderProfile: require("./provider-profile.png"),
  parrotCarouselSchedule: require("./Scheduling.png"),
  parrotCarouselMetricsPlanning: require("./matrics-planning.png"),
  parrotCarouselForecasting: require("./Forecasting.png"),
  parrotCarouselTaskManagement: require("./taskmanagement.png"),
  InvitePatient: require("./InvitePatient.png"),
  InvitePatientBtn: require("./InvitePatientBtn.png"),
  InvitePatientDetails: require("./InvitePatientDetails.png"),
  InviteProvider: require("./InviteProvider.png"),
  InviteProviderBtn: require("./InviteProviderBtn.png"),
  InviteProviderDetails: require("./InviteProviderDetails.png"),
  ProfileFlowImg: require("./ProfileFlowImg.png"),
  ProviderProfileFlowImg: require("./ProviderProfileFlowImg.png"),
  PatientProfileFlowImg: require("./PatientProfileFlowImg.png"),

  // Other Images
  hospital1: require("./image_430.png"),
  hospital2: require("./image_431.png"),
  // hospital3: require("./image_433.png"),
  hospital4: require("./image_434.png"),
  hospital5: require("./image_435.png"),
  hospital6: require("./image_429.png"),
  hospital7: require("./image_437.png"),
  hospital8: require("./image_432.png"),
  hospital9: require("./image_438.png"),
  hospital10: require("./image_440.png"),
  hospital11: require("./image_4333.png"),
  hospital12: require("./image_441.png"),
  hospital13: require("./image_442.png"),
  hospital14: require("./image_436.png"),
  hospital15: require("./image_443.png"),
  hospital16: require("./image_444.png"),
  videoCallImg: require("./VideoImg.png"),

  activeTick: require("./activesave.png"),
  ClinicProvider: require("./ClinicProvider.png"),


  // VideoCall Images
  sliderShowHideImg: require('./sliderCloseIcon.png'),
  screenSharingImg: require('./screenSharing.png'),
  videoOnImg: require('./videoOn.png'),
  videoOffImg: require('./videoOff.png'),
  micOnImg: require('./micOn.png'),
  endCallImg: require('./endCall.png'),
  videoChatImg: require('./mettingChat.png'),
  WaitingRoom: require('./WaitingRoom.png'),
  videoTileImg: require('./videoTiles.png'),
  fullScreenImg: require('./fullScreen.png'),
  SessionChat: require('./SessionChat.png'),
  Insurance: require('./Insurance.png'),
  License: require('./License.png'),
  filter1: require('./filter1.jpg'),
  filter2: require('./filter2.jpg'),
  noFilter: require('./noFilter.png'),
  blurFilter: require('./blurFilter.png'),
  FirstCompliance: require('./FirstCompliance.png'),
  SecondCompliance: require('./SecondCompliance.png'),
  ThirdCompliance: require('./ThirdCompliance.png'),
  ForthCompliance: require('./ForthCompliance.png'),
  SecurityCheck: require('./SecurityCheck.png'),
  HomePageProvider: require('./HomePageProvider.png'),
  DotsBg: require('./DotsBg.png'),
  News1: require('./News1.png'),
  News2: require('./News2.png'),
  News3: require('./News3.png'),
  PlatformUpgradesImage: require('./PlatformUpgradesImage.png'),
  ClinicPictureOption: require('./ClinicPictureOption.png'),
  EmployerWebsitePicture: require('./EmployerWebsitePicture.png'),
  Hipaa: require('./Hipaa.png'),
  Fedramp: require('./Fedramp.png'),
  AICPASOC: require('./AICPASOC.png'),
  Hitech: require('./Hitech.png'),
  Hitrust: require('./Hitrust.png'),
  GDPR: require('./GDPR.png'),

  //security&trust images

  SecurityTrust1: require('./SecurityTrust1.png'),
  SecurityTrust2: require('./SecurityTrust2.png'),
  SecurityTrustImage: require('./SecurityTrustImage.png'),
  SecurityImage: require('./SecurityImage.png'),
  TermsOfUse: require('./TermsOfUse.png'),
  TermsOfUse1: require('./TermsOfUse1.png'),
  PrivacyNotice: require('./PrivacyNotice.png'),
  PrivacyNotice1: require('./PrivacyNotice1.png'),
  HelpAndSupport: require('./HelpAndSupport.png'),
  HelpAndSupport1: require('./HelpAndSupport1.png'),

  //social handle images

  HomeFacebook: require('./HomeFacebook.png'),
  HomeInstagram: require('./HomeInstagram.png'),
  HomeX: require('./HomeX.png'),
  HomeLinkedin: require('./HomeLinkedin.png'),

};
export default images;
